import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

const SuccessIcon = styled(CheckIcon)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: '#1A8611FF',
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: 'white',
}));

export default SuccessIcon;
