import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ArchiveIcon from '@mui/icons-material/Archive';
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import AddButton from '../utils/buttons/AddButton';
import WebIcon from '../utils/icons/WebIcon';
import TrueFalseIcon from '../utils/TrueFalseIcon';
import { deleteBookVariant, syncBookVariantToShop } from '../../api';
import NoImage from '../../assets/images/noimage.jpg';
import ConfirmationDialog from '../utils/dialogs/ConfirmationDialog';

const BookVariantList = ({ accessToken, shopUuid, enumGroups, book, variants }) => {
  const { CONDITION_CATEGORY_OPTIONS } = enumGroups;
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogValue, setConfirmDialogValue] = useState(null);
  const navigate = useNavigate();

  const handleConfirmArchiveBookVariant = (e, bookVariantUuid) => {
    setOpenConfirmDialog(true);
    setConfirmDialogValue(bookVariantUuid);
  };

  const handleCloseConfirmDialog = async (bookVariantUuid) => {
    console.log('handleCloseConfirmDialog', bookVariantUuid);
    setOpenConfirmDialog(false);
    setConfirmDialogValue(null);

    if (bookVariantUuid) {
      await deleteBookVariant(accessToken, shopUuid, bookVariantUuid);

      variants.filter((bookVariant) => bookVariant.uuid !== bookVariantUuid);
    }
  };

  const handleAddBookVariant = (event) => {
    event.preventDefault();

    navigate(`/books/${book.uuid}/variants/create`);
  };

  const handleEditBookVariant = (event, variantUuid) => {
    event.preventDefault();

    navigate(`/books/${book.uuid}/variants/${variantUuid}/edit`);
  };

  const handleSyncBookVariantToShop = async (book, variant) => {
    await syncBookVariantToShop(accessToken, shopUuid, book.uuid, variant.uuid);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <AddButton
        label={'Přidat'}
        variant="contained"
        color="success"
        onClick={handleAddBookVariant}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Obálka</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Stav</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Přebal</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Katalogové číslo</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Prodejní cena</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Skladem (ks)</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} />
              <TableCell sx={{ fontWeight: 'bold' }}>Eshop</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Poslední změna</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {variants.map((variant) => (
              <TableRow
                key={variant.uuid}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <img src={variant.image || NoImage} alt={book.title} height="60" />
                </TableCell>
                <TableCell>
                  {variant.conditionCategory !== '' ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          padding: 1,
                          backgroundColor:
                            CONDITION_CATEGORY_OPTIONS.find(
                              (option) => option.value === variant.conditionCategory
                            ).color || 'grey.500',
                        }}
                      />
                      <Typography variant="body1" sx={{ marginLeft: 1 }}>
                        {CONDITION_CATEGORY_OPTIONS.find(
                          (option) => option.value === variant.conditionCategory
                        ).label || 'Neznámý'}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="body1" sx={{ marginLeft: 1 }}>
                      Neznámý
                    </Typography>
                  )}
                </TableCell>
                <TableCell>{variant.bookBindingHasWrap ? 'Ano' : 'Ne'}</TableCell>
                <TableCell>{variant.shopExternalId}</TableCell>
                <TableCell>{variant.sellingPrice} Kč</TableCell>
                <TableCell>{variant.stockCount}</TableCell>
                <TableCell>
                  {variant.comment && (
                    <Tooltip title={variant.comment}>
                      <CommentIcon />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  <TrueFalseIcon flag={variant.publishedAt} />
                  {book.permalink && (
                    <IconButton component={Link} href={book.permalink} target="_blank">
                      <WebIcon />
                    </IconButton>
                  )}
                  {!variant.publishedAt && (
                    <IconButton onClick={() => handleSyncBookVariantToShop(book, variant)}>
                      <SyncIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>
                  {dayjs(new Date(variant.updatedAt)).format('DD.MM.YYYY HH:mm:ss')}
                </TableCell>
                <TableCell>
                  <Tooltip title="Upravit">
                    <EditIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={(e) => handleEditBookVariant(e, variant.uuid)}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Archivovat">
                    <ArchiveIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={(e) => handleConfirmArchiveBookVariant(e, variant.uuid)}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {confirmDialogValue && (
        <ConfirmationDialog
          id="confirm-dialog-archive-book-variant"
          keepMounted
          open={openConfirmDialog}
          onClose={handleCloseConfirmDialog}
          value={confirmDialogValue}
          confirmMessage={'Přejete si opravdu archivovat tuto položku?'}
        />
      )}
    </Box>
  );
};

export default BookVariantList;
