import React from 'react';
import PropTypes from 'prop-types';
import ArchiveIcon from '@mui/icons-material/Archive';
import ActionButton from './ActionButton';

export default function RestoreButton({
  label,
  onClick,
  sx,
  variant = 'contained',
  color = 'secondary',
}) {
  return (
    <ActionButton
      variant={variant}
      color={color}
      label={label}
      onClick={onClick}
      startIcon={<ArchiveIcon />}
      sx={sx}
    />
  );
}

RestoreButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
