import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import ActionButton from './ActionButton';

export default function EditButton({
  label,
  onClick,
  sx,
  variant = 'contained',
  color = 'success',
}) {
  return (
    <ActionButton
      variant={variant}
      color={color}
      label={label}
      onClick={onClick}
      startIcon={<EditIcon />}
      sx={sx}
    />
  );
}

EditButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
