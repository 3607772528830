import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AssignmentReturnRoundedIcon from '@mui/icons-material/AssignmentReturnRounded';

export default function BackButton({ label, url, sx, variant = 'contained' }) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(url, { replace: true });
  };

  return (
    <Button
      variant={variant}
      onClick={handleBack}
      startIcon={<AssignmentReturnRoundedIcon />}
      sx={sx}
    >
      {label}
    </Button>
  );
}

BackButton.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
