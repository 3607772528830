import React from 'react';
import Typography from '@mui/material/Typography';
import DashboardCard from '../utils/DashboardCard';

const UserDashboard = ({ data }) => {
  const content = (
    <>
      {false && (
        <Typography sx={{ fontSize: 14 }} variant="body2" color="text.secondary">
          {`Počet mých projektů: ${data.projects_count}`}
        </Typography>
      )}
      <Typography sx={{ fontSize: 14 }} variant="body2" color="text.secondary">
        {`Počet obchodů které spravuji: ${data.shops_count}`}
      </Typography>
    </>
  );

  return <DashboardCard title={`Můj dashboard`} content={content} />;
};

export default UserDashboard;
