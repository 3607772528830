import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CategoryIcon from '@mui/icons-material/Category';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ListContentHeader from '../layout/ListContentHeader';
import EditButton from '../utils/buttons/EditButton';
import IsActiveSwitch from '../utils/IsActiveSwitch';
import Loader from '../utils/Loader';
import RichObjectTreeView from '../utils/RichObjectTreeView';
import TrueFalseIcon from '../utils/TrueFalseIcon';
import { getCategories, updateCategory } from '../../api';
import { formatCategoryData, flattenTree } from '../../helpers';
import AddButton from '../utils/buttons/AddButton';
import ClearFilterButton from '../utils/buttons/ClearFilterButton';

const CategoryList = ({ accessToken, shopUuid }) => {
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryTree, setCategoryTree] = useState([]);
  const [noCategoryBooksCount, setNoCategoryBooksCount] = useState(0);
  const [showCategoryTree, setShowCategoryTree] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const handleResetFilter = () => {
    setFilterText('');
  };

  const handleChangeIsCategoryActive = async (categoryUuid, isActive, category) => {
    await updateCategory(accessToken, shopUuid, categoryUuid, { ...category, isActive });
  };

  const handleCreateCategory = () => {
    navigate(`/categories/create`);
  };

  const handleEditCategory = (event, categoryUuid) => {
    navigate(`/categories/${categoryUuid}/edit`);
  };

  const handleToggleCategoryTree = (event) => {
    setShowCategoryTree(!showCategoryTree);
  };

  const handleUpdateCategoryTree = (event, data) => {
    console.log('handleUpdateCategoryTree', data);
  };

  const renderCategoryTitle = (category) => {
    const marginLeft = 20 * category.depth;
    const fontSize = 16 - category.depth;

    return <div style={{ marginLeft, fontSize }}>{category.title}</div>;
  };

  useEffect(() => {
    if (filterText === '') {
      setFilteredCategories(categories);
    } else {
      setFilteredCategories(
        filteredCategories.filter((category) => {
          return category.title.toLowerCase().includes(filterText.toLowerCase());
        })
      );
    }
  }, [filterText]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await getCategories(accessToken, shopUuid);

        if (res && res.data.data.categories.length > 0) {
          const categoryTree = res.data.data.categories[0];
          const flattenedCategoryTree = flattenTree(formatCategoryData(categoryTree.children));

          setCategories(flattenedCategoryTree);
          setCategoryTree(categoryTree);
          setFilteredCategories(flattenedCategoryTree);
          setNoCategoryBooksCount(res.data.data.noCategoryBooksCount);
        } else {
          console.error('Error fetching categories');

          setError('Nepodařilo se stáhnout seznam kategorií');
        }
      } catch (err) {
        console.error(err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, [accessToken, shopUuid]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <ListContentHeader icon={<CategoryIcon />} title="Kategorie" />
      {categories.length > 0 && (
        <Button onClick={handleToggleCategoryTree}>
          Zobrazit {showCategoryTree ? 'výpis' : 'strom'} kategorií
        </Button>
      )}
      {error && <Alert severity="error">{error}</Alert>}
      {!showCategoryTree && categories.length > 0 && (
        <Box sx={{ margin: 2 }}>
          <Grid container>
            <Grid item>
              <TextField
                id="outlined-basic"
                placeholder="Název, ..."
                variant="outlined"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                size={'small'}
              />
            </Grid>
            <Grid>
              <ClearFilterButton
                label="Vymazat filtr"
                onClick={handleResetFilter}
                startIcon={<FilterAltOffIcon />}
                sx={{ marginLeft: 1 }}
              />
            </Grid>
          </Grid>
          <Typography variant="overline" display="block" sx={{ marginLeft: 1 }} gutterBottom>
            nalezeno {filteredCategories.length} kategorií
          </Typography>
        </Box>
      )}
      <AddButton
        label={'Přidat'}
        variant="contained"
        color="success"
        onClick={handleCreateCategory}
      />
      {filteredCategories.length > 0 ? (
        <Box>
          {!showCategoryTree ? (
            <TableContainer component={Paper} sx={{ marginTop: 5 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Název</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Počet knih</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Aktivní</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Eshop</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Poslední změna</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={'no_category'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>Nezařazené</TableCell>
                    <TableCell>{noCategoryBooksCount}</TableCell>
                    <TableCell colSpan={4} />
                  </TableRow>
                  {filteredCategories.map((category) => (
                    <TableRow
                      key={category.uuid}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{renderCategoryTitle(category)}</TableCell>
                      <TableCell>{category.books_count}</TableCell>
                      <TableCell>
                        <IsActiveSwitch
                          itemId={category.uuid}
                          defaultIsActive={category.is_active}
                          item={category}
                          onClickSwitch={handleChangeIsCategoryActive}
                        />
                      </TableCell>
                      <TableCell>
                        <TrueFalseIcon flag={category.published_at} />
                      </TableCell>
                      <TableCell>
                        {dayjs(new Date(category.updated_at)).format('DD.MM.YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell>
                        <EditButton
                          label={'Upravit'}
                          onClick={(e) => handleEditCategory(e, category.uuid)}
                          sx={{ cursor: 'pointer' }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <RichObjectTreeView
              data={categoryTree}
              expandedIds={[]}
              onChange={handleUpdateCategoryTree}
            />
          )}
        </Box>
      ) : (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Nebyly nalezeny žádné kategorie
            </Typography>
          </Box>
        </Container>
      )}
    </React.Fragment>
  );
};

export default CategoryList;
