import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const ContentHeader = ({ title, navButtonBack }) => {
  return (
    <Toolbar
      component="nav"
      variant="dense"
      sx={{ justifyContent: 'space-between', overflowX: 'auto', marginTop: 2 }}
    >
      <Typography
        component="h2"
        variant="h5"
        color="inherit"
        align="center"
        noWrap
        sx={{ flex: 1, marginRight: 2 }}
      >
        {title}
      </Typography>
      {navButtonBack}
    </Toolbar>
  );
};

export default ContentHeader;
