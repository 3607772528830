import React, { useState, forwardRef } from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, useTreeItem } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';

export default function RichObjectTreeView({ data, expandedIds, onChange }) {
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);

    onChange(event, nodeIds);
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? expandedIds : []));
  };

  // const extractRootNodes = (nodes) => {
  //   return nodes.map(node => Array.isArray(node.children) && node.children.length > 0 && extractRootNodes(node.children));
  // };
  //
  // useEffect(() => {
  //   if (data.length) {
  //     setExpanded(extractRootNodes(data));
  //     console.log(extractRootNodes(data));
  //   }
  // }, [data]);

  const CustomContent = forwardRef(function CustomContent(props, ref) {
    const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
      handleSelection(event);
    };

    return (
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
          {label}
        </Typography>
      </div>
    );
  });

  const CustomTreeItem = forwardRef(function CustomTreeItem(props, ref) {
    return <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />;
  });

  const renderTree = (nodes) => (
    <CustomTreeItem key={nodes.uuid} nodeId={nodes.uuid} label={nodes.title}>
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </CustomTreeItem>
  );

  return (
    <Box sx={{ height: 300, flexGrow: 1, maxWidth: 600, overflowY: 'auto' }}>
      <Box sx={{ mb: 1 }}>
        <Button onClick={handleExpandClick}>
          {expanded.length === 0 ? 'Rozbalit vše' : 'Sbalit vše'}
        </Button>
      </Box>
      <TreeView
        aria-label="categories"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        multiSelect
        sx={{ height: 300, flexGrow: 1, maxWidth: 600, overflowY: 'auto' }}
      >
        {renderTree(data)}
      </TreeView>
    </Box>
  );
}
