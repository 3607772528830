import { styled } from '@mui/material/styles';
import LanguageIcon from '@mui/icons-material/Language';

const WebIcon = styled(LanguageIcon)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: '#1A8611FF',
  textAlign: 'center',
  color: 'white',
}));

export default WebIcon;
