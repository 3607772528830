import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchButton({
  label,
  onClick,
  sx,
  type = 'submit',
  variant = 'contained',
  color = 'success',
}) {
  const sxDefault = {
    margin: 1,
    ...sx,
  };

  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      onClick={onClick}
      startIcon={<SearchIcon />}
      sx={sxDefault}
    >
      {label}
    </Button>
  );
}

SearchButton.propTypes = {
  label: PropTypes.string.isRequired,
};
