import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import RemoveTrailingSlash from './RemoveTrailingSlash';
import BookForm from '../components/book/BookForm';
import BookList from '../components/book/BookList';
import BookVariantFormWrapper from '../components/book/BookVariantFormWrapper';
import CategoryForm from '../components/category/CategoryForm';
import CategoryList from '../components/category/CategoryList';
import Dashboard from '../components/Dashboard';
import LibraryBookSearchForm from '../components/library/LibraryBookSearchForm';
import LoginForm from '../components/auth/LoginForm';
import Logout from '../components/auth/Logout';
import RegistrationForm from '../components/auth/RegistrationForm';
import OrderList from '../components/order/OrderList';
import PasswordReset from '../components/auth/PasswordReset';
import ShopForm from '../components/shop/ShopForm';
import ShopList from '../components/shop/ShopList';
import UserAccount from '../components/UserAccount';
import UserForm from '../components/user/UserForm';
import UserList from '../components/user/UserList';
import Loader from '../components/utils/Loader';
import { useAuth } from '../hooks/useAuth';
import { useEnums } from '../hooks/useEnums';

function Router() {
  const { user } = useAuth();
  const accessToken = user ? user.access_token : null;
  const currentShop = user ? user.currentShop : null;
  const currentProject = user ? user.currentProject : null;
  const isSuperAdmin = user ? user.isSuperAdmin || false : false;
  const isAdmin = user ? user.isAdmin || isSuperAdmin || false : false;
  const { enumGroups, isLoading } = useEnums(accessToken, currentShop?.uuid);

  if (!user) {
    return (
      <React.Fragment>
        <RemoveTrailingSlash />
        <Routes>
          <Route index element={<LoginForm />} />
          <Route path="registration" element={<RegistrationForm />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </React.Fragment>
    );
  }

  if (!currentShop) {
    return (
      <React.Fragment>
        <RemoveTrailingSlash />
        <Routes>
          <Route index element={<Dashboard accessToken={accessToken} project={currentProject} />} />
          <Route
            path="user/account"
            element={
              <ProtectedRoute token={accessToken}>
                <UserAccount accessToken={accessToken} />
              </ProtectedRoute>
            }
          />
          <Route path="logout" element={<Logout />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </React.Fragment>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <RemoveTrailingSlash />
      <Routes>
        <Route
          index
          element={
            <Dashboard accessToken={accessToken} project={currentProject} shop={currentShop} />
          }
        />
        <Route path="books" exact={true}>
          <Route
            index={true}
            element={
              <ProtectedRoute token={accessToken}>
                <BookList
                  accessToken={accessToken}
                  shopUuid={currentShop.uuid}
                  enumGroups={enumGroups}
                  isArchive={false}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="archive"
            element={
              <ProtectedRoute token={accessToken}>
                <BookList
                  accessToken={accessToken}
                  shopUuid={currentShop.uuid}
                  enumGroups={enumGroups}
                  isArchive={true}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="create"
            element={
              <ProtectedRoute token={accessToken}>
                <BookForm
                  accessToken={accessToken}
                  shopUuid={currentShop.uuid}
                  enumGroups={enumGroups}
                  isCreate={true}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path=":uuid/edit"
            element={
              <ProtectedRoute token={accessToken}>
                <BookForm
                  accessToken={accessToken}
                  shopUuid={currentShop.uuid}
                  enumGroups={enumGroups}
                  isCreate={false}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path=":uuid/variants/create"
            element={
              <ProtectedRoute token={accessToken}>
                <BookVariantFormWrapper
                  accessToken={accessToken}
                  shopUuid={currentShop.uuid}
                  enumGroups={enumGroups}
                  isCreate={true}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path=":uuid/variants/:variantUuid/edit"
            element={
              <ProtectedRoute token={accessToken}>
                <BookVariantFormWrapper
                  accessToken={accessToken}
                  shopUuid={currentShop.uuid}
                  enumGroups={enumGroups}
                  isCreate={false}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="search"
            element={
              <ProtectedRoute token={accessToken}>
                <LibraryBookSearchForm accessToken={accessToken} />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="categories" exact={true}>
          <Route
            index={true}
            element={
              <ProtectedRoute token={accessToken}>
                <CategoryList accessToken={accessToken} shopUuid={currentShop.uuid} />
              </ProtectedRoute>
            }
          />
          <Route
            path="create"
            element={
              <ProtectedRoute token={accessToken}>
                <CategoryForm
                  accessToken={accessToken}
                  shopUuid={currentShop.uuid}
                  isCreate={true}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path=":uuid/edit"
            element={
              <ProtectedRoute token={accessToken}>
                <CategoryForm
                  accessToken={accessToken}
                  shopUuid={currentShop.uuid}
                  isCreate={false}
                />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="orders" exact={true}>
          <Route
            index={true}
            element={
              <ProtectedRoute token={accessToken}>
                <OrderList accessToken={accessToken} shopUuid={currentShop.uuid} />
              </ProtectedRoute>
            }
          />
        </Route>
        {isSuperAdmin && (
          <Route path="users" exact={true}>
            <Route
              index={true}
              element={
                <ProtectedRoute token={accessToken}>
                  <UserList accessToken={accessToken} shopUuid={currentShop.uuid} />
                </ProtectedRoute>
              }
            />
            <Route
              path="create"
              element={
                <ProtectedRoute token={accessToken}>
                  <UserForm
                    accessToken={accessToken}
                    shopUuid={currentShop.uuid}
                    isCreate={true}
                    isAdmin={isAdmin}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/edit"
              element={
                <ProtectedRoute token={accessToken}>
                  <UserForm
                    accessToken={accessToken}
                    shopUuid={currentShop.uuid}
                    isCreate={false}
                    isAdmin={isAdmin}
                  />
                </ProtectedRoute>
              }
            />
          </Route>
        )}
        {isSuperAdmin && (
          <Route path="shops" exact={true}>
            <Route
              index={true}
              element={
                <ProtectedRoute token={accessToken}>
                  <ShopList accessToken={accessToken} projectUuid={currentProject.uuid} />
                </ProtectedRoute>
              }
            />
            <Route
              path="create"
              element={
                <ProtectedRoute token={accessToken}>
                  <ShopForm
                    accessToken={accessToken}
                    projectUuid={currentProject.uuid}
                    isCreate={true}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path=":uuid/edit"
              element={
                <ProtectedRoute token={accessToken}>
                  <ShopForm
                    accessToken={accessToken}
                    projectUuid={currentProject.uuid}
                    isCreate={false}
                  />
                </ProtectedRoute>
              }
            />
          </Route>
        )}
        <Route
          path="user/account"
          element={
            <ProtectedRoute token={accessToken}>
              <UserAccount accessToken={accessToken} />
            </ProtectedRoute>
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </React.Fragment>
  );
}

export default Router;
