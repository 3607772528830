import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

export default function MultiSelectAutocomplete({
  onChange,
  onInputChange,
  options,
  label,
  placeholder,
  // defaultValue,
  selectedValues,
  renderOption,
  size,
  isLoading,
  ...otherProps
}) {
  const [value, setValue] = useState(selectedValues);
  const [inputValue, setInputValue] = useState([]);

  const handleChange = (event, currentValue) => {
    event.preventDefault();

    setValue(currentValue);

    onChange(event, currentValue);
  };

  const handleInputChange = (event, currentValue) => {
    setInputValue([...inputValue, currentValue]);

    onInputChange(currentValue);
  };

  const defaultRenderOption = (props, option) => {
    return (
      <li {...props} key={`${option.id}`}>
        {option.label + ' (' + option.date_bio + ')'}
      </li>
    );
  };

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={options}
      getOptionLabel={(option) => option.label}
      // defaultValue={defaultValue}
      value={value}
      // inputValue={inputValue}
      // filterSelectedOptions={filterSelectedOptions || false}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => handleChange(event, newValue)}
      onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
      renderOption={renderOption || defaultRenderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: isLoading && <CircularProgress size={'2rem'} />,
          }}
          size={size}
        />
      )}
      {...otherProps}
    />
  );
}

MultiSelectAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  // onInputChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedValues: PropTypes.array.isRequired,
  renderOption: PropTypes.func,
};
