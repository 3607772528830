import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'dayjs/locale/cs';
import BookVariantForm from './BookVariantForm';
import Loader from '../utils/Loader';
import { getBookVariant } from '../../api';

const BookVariantFormWrapper = ({
  accessToken,
  shopUuid,
  enumGroups,
  isCreate,
  initialData = {},
}) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { CONDITION_CATEGORY_OPTIONS } = enumGroups;

  const { uuid: bookUuid, variantUuid } = useParams();

  const formInitialValues = {
    uuid: null,
    purchasePrice: '',
    sellingPrice: '',
    bookBindingHasWrap: false,
    conditionCategory: CONDITION_CATEGORY_OPTIONS[1].value,
    stockCount: 1,
    comment: '',
    commentInternal: '',
    image: null,
    galleryImages: [],
    createdAt: null,
    updatedAt: null,
  };

  const [bookVariantInitialData, setBookVariantInitialData] = useState({
    ...initialData,
    ...formInitialValues,
  });

  useEffect(() => {
    const fetchBookVariant = async () => {
      try {
        setIsLoading(true);

        const res = await getBookVariant(accessToken, shopUuid, bookUuid, variantUuid);

        if (res) {
          const bookVariantDataResult = res.data;

          await setBookVariantInitialData({
            uuid: bookVariantDataResult.uuid,
            purchasePrice: bookVariantDataResult.purchase_price,
            sellingPrice: bookVariantDataResult.selling_price,
            bookBindingHasWrap: bookVariantDataResult.book_binding_has_wrap || false,
            conditionCategory: bookVariantDataResult.condition_category || '',
            stockCount: bookVariantDataResult.stock_count,
            comment: bookVariantDataResult.comment,
            commentInternal: bookVariantDataResult.comment_internal,
            image: bookVariantDataResult.image,
            galleryImages: bookVariantDataResult.gallery_images,
            createdAt: bookVariantDataResult.created_at,
            updatedAt: bookVariantDataResult.updated_at,
          });
        } else {
          console.error('Book variant not found');

          setError('Varianta knihy nenalezena');
        }
      } catch (err) {
        console.error('Fetch Book variant error', err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (bookUuid && !isCreate) {
      fetchBookVariant();
    }
  }, [accessToken, shopUuid, isCreate, bookUuid]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <BookVariantForm
      accessToken={accessToken}
      shopUuid={shopUuid}
      enumGroups={enumGroups}
      isCreate={isCreate}
      initialData={bookVariantInitialData}
      initError={error}
    />
  );
};

export default BookVariantFormWrapper;
