import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import SmallLoader from '../utils/SmallLoader';

const IsActiveSwitch = ({
  itemId,
  defaultIsActive,
  item,
  onClickSwitch,
  successColor = 'success',
}) => {
  const [isActive, setIsActive] = useState(defaultIsActive);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeIsActive = async (event) => {
    setIsLoading(true);

    const newIsActive = event.target.checked;

    setIsActive(newIsActive);

    try {
      await onClickSwitch(itemId, newIsActive, item);
    } catch (err) {
      console.error('Item is active switch error', err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <Switch
      checked={isActive}
      onChange={handleChangeIsActive}
      color={isActive ? successColor : 'error'}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
};

export default IsActiveSwitch;
