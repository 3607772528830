import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import MiniDrawerSideMenu, { DrawerHeader } from './MiniDrawerSideMenu';
import ResponsiveAppBar from './ResponsiveAppBar';
import Loader from '../utils/Loader';
import Router from '../../routes';
import { useAuth } from '../../hooks/useAuth';
import { getShops, getProjects } from '../../api';
import bgImage from '../../assets/images/books-2596809_1920.jpg';

const drawerWidth = 240;

export default function Layout() {
  const { user, setCurrentProject, setCurrentShop, logout } = useAuth();
  const accessToken = user ? user.access_token : null;
  const currentProject = user ? user.currentProject : null;
  const currentShop = user ? user.currentShop : null;
  const [open, setOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [shops, setShops] = useState([]);
  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const [isShopsLoading, setIsShopsLoading] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const defaultStyle = {
    flexGrow: 1,
    p: 3,
  };

  const mainStyle = !user
    ? {
        ...defaultStyle,
        backgroundImage: `url('${bgImage}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }
    : defaultStyle;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setIsProjectsLoading(true);

        const res = await getProjects(accessToken);

        setProjects(res.data);
        // setError(null);

        if (res.data.length === 1) {
          setCurrentProject(res.data[0]);
        }
      } catch (err) {
        console.error(err);

        if (err.response) {
          if (err.response.data.message === 'Unauthenticated.') {
            logout();
          }

          // setError(err.response.data.message);
        }
      } finally {
        setIsProjectsLoading(false);
      }
    };

    if (accessToken) {
      fetchProjects();
    }
  }, [accessToken]);

  useEffect(() => {
    const fetchShops = async () => {
      try {
        setIsShopsLoading(true);

        const res = await getShops(accessToken, currentProject.uuid);
        const shopsList = res.data.filter((shop) => shop.is_active === true);

        setShops(shopsList);
        // setError(null);

        if (shopsList.length === 1) {
          setCurrentShop(shopsList[0]);
        }
      } catch (err) {
        console.error(err);

        if (err.response) {
          if (err.response.data.message === 'Unauthenticated.') {
            logout();
          }

          // setError(err.response.data.message);
        }
      } finally {
        setIsShopsLoading(false);
      }
    };

    if (accessToken) {
      fetchShops();
    }
  }, [accessToken]);

  if (isProjectsLoading || isShopsLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ResponsiveAppBar
        drawerWidth={drawerWidth}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        shops={shops}
      />
      {user && currentShop && (
        <MiniDrawerSideMenu
          drawerWidth={drawerWidth}
          handleDrawerClose={handleDrawerClose}
          open={open}
          projects={projects}
        />
      )}
      <Box component="main" sx={mainStyle}>
        {user && <DrawerHeader />}
        <Container>
          <Router />
        </Container>
      </Box>
    </Box>
  );
}
