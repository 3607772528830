import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MultiSelectAutocomplete from '../utils/MultiSelectAutocomplete';

const CategorySelect = ({ ...props }) => {
  const updateCategoriesSearchTerm = () => {};

  const renderCategoryOption = (props, option, { selected, inputValue }) => {
    const matches = match(option.label, inputValue);
    const parts = parse(option.label, matches);
    const marginLeft = 20 * option.depth;
    const fontSize = 16 - option.depth;

    return (
      <li {...props} key={`${option.uuid}`}>
        <div style={{ marginLeft, fontSize }}>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </li>
    );
  };

  return (
    <MultiSelectAutocomplete
      label="Kategorie"
      placeholder="Kategorie"
      onInputChange={updateCategoriesSearchTerm}
      filterSelectedOptions={true}
      renderOption={renderCategoryOption}
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      {...props}
    />
  );
};

export default CategorySelect;
