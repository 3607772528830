import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SyncIcon from '@mui/icons-material/Sync';
import BookAuthorsModal from './BookAuthorsModal';
import BookCategoriesModal from './BookCategoriesModal';
import BookDetailModal from './BookDetailModal';
import BookVariantsModal from './BookVariantsModal';
import CategorySelect from './CategorySelect';
import ListContentHeader from '../layout/ListContentHeader';
import ApplyFilterButton from '../utils/buttons/ApplyFilterButton';
import ArchiveButton from '../utils/buttons/ArchiveButton';
import BackButton from '../utils/buttons/BackButton';
import ClearFilterButton from '../utils/buttons/ClearFilterButton';
import DeleteButton from '../utils/buttons/DeleteButton';
import DetailButton from '../utils/buttons/DetailButton';
import EditButton from '../utils/buttons/EditButton';
import RestoreButton from '../utils/buttons/RestoreButton';
import ConfirmationDialog from '../utils/dialogs/ConfirmationDialog';
import WebIcon from '../utils/icons/WebIcon';
import ImageModal from '../utils/ImageModal';
import Loader from '../utils/Loader';
import SmallLoader from '../utils/SmallLoader';
import TrueFalseIcon from '../utils/TrueFalseIcon';
import { getBooks, getCategories, deleteBook, syncBookToShop, restoreBook } from '../../api';
import { formatCategoryData, flattenTree } from '../../helpers';
import NoImage from '../../assets/images/noimage.jpg';

const BookList = ({ accessToken, shopUuid, enumGroups, isArchive }) => {
  const [error, setError] = useState(null);
  const [books, setBooks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [filterText, setFilterText] = useState('');
  const [filterCategories, setFilterCategories] = useState([]);
  const [filterNoDefaultImage, setFilterNoDefaultImage] = useState(false);
  const [filterNoCustomImage, setFilterNoCustomImage] = useState(false);
  const [filterNoCategory, setFilterNoCategory] = useState(false);
  const [filterNoVariant, setFilterNoVariant] = useState(false);
  const [filterNotSynced, setFilterNotSynced] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [totalResults, setTotalResults] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchingMore, setIsSearchingMore] = useState(false);
  const [bookDetailModalOpen, setBookDetailModalOpen] = useState(false);
  const [bookDetailModalData, setBookDetailModalData] = useState([]);
  const [bookAuthorsModalOpen, setBookAuthorsModalOpen] = useState(false);
  const [bookAuthorsModalData, setBookAuthorsModalData] = useState([]);
  const [bookCategoriesModalOpen, setBookCategoriesModalOpen] = useState(false);
  const [bookCategoriesModalData, setBookCategoriesModalData] = useState([]);
  const [bookVariantsModalOpen, setBookVariantsModalOpen] = useState(false);
  const [bookVariantsModalData, setBookVariantsModalData] = useState([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageModalData, setImageModalData] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogType, setConfirmDialogType] = useState(null);
  const [confirmDialogValue, setConfirmDialogValue] = useState(null);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');

  const navigate = useNavigate();

  const handleEditBook = (event, bookUuid) => {
    navigate(`/books/${bookUuid}/edit`);
  };

  const handleConfirmArchiveBook = (e, bookUuid) => {
    setOpenConfirmDialog(true);
    setConfirmDialogType('ARCHIVE');
    setConfirmDialogValue(bookUuid);
    setConfirmDialogMessage('Přejete si opravdu archivovat tuto položku?');
  };

  const handleConfirmRestoreBook = (e, bookUuid) => {
    setOpenConfirmDialog(true);
    setConfirmDialogType('RESTORE');
    setConfirmDialogValue(bookUuid);
    setConfirmDialogMessage('Přejete si opravdu obnovit z archivu tuto položku?');
  };

  const handleConfirmDeleteBook = (e, bookUuid) => {
    setOpenConfirmDialog(true);
    setConfirmDialogType('DELETE');
    setConfirmDialogValue(bookUuid);
    setConfirmDialogMessage('Přejete si opravdu smazat tuto položku?');
  };

  const handleCloseConfirmDialog = async (bookUuid) => {
    const forceDelete = confirmDialogType === 'DELETE' && confirmDialogValue === bookUuid;
    const actionType = confirmDialogType;

    setOpenConfirmDialog(false);
    setConfirmDialogType(null);
    setConfirmDialogValue(null);
    setConfirmDialogMessage('');

    if (!bookUuid || !['ARCHIVE', 'DELETE', 'RESTORE'].includes(actionType)) {
      console.error('bookUuid', bookUuid);
      console.error('actionType', actionType);

      return;
    }

    if (actionType === 'ARCHIVE' || actionType === 'DELETE') {
      await deleteBook(accessToken, shopUuid, bookUuid, forceDelete);
    }

    if (actionType === 'RESTORE') {
      await restoreBook(accessToken, shopUuid, bookUuid);
    }

    const updatedBooks = books.filter((book) => book.uuid !== bookUuid);

    setBooks(updatedBooks);
  };

  const handleSubmitFilter = async (event) => {
    event.preventDefault();

    const params = {};

    if (filterText !== '') {
      params.searchTerm = filterText;
    }

    if (filterCategories.length) {
      params.categories = filterCategories;
    }

    if (filterNoDefaultImage) {
      params.noDefaultImage = true;
    }

    if (filterNoCustomImage) {
      params.noCustomImage = true;
    }

    if (filterNoCategory) {
      params.noCategory = true;
    }

    if (filterNoVariant) {
      params.noVariant = true;
    }

    if (filterNotSynced) {
      params.notSynced = true;
    }

    await setBooks([]);
    await setFilterParams(params);
    // if (Object.keys(params).length) {
    //   setFilterParams(params);
    // }
  };

  const handleSetCategoryFilter = (values) => {
    const uuids = values.map((value) => value.uuid);

    setFilterCategories(uuids);
  };

  const handleResetFilter = async () => {
    await setFilterText('');
    await setFilterCategories([]);
    await setFilterNoDefaultImage(false);
    await setFilterNoCustomImage(false);
    await setFilterNoCategory(false);
    await setFilterNoVariant(false);
    await setFilterNotSynced(false);
    await setFilterParams({});
    await setBooks([]);
  };

  const handleClearFilterInput = () => {
    setFilterText('');
  };

  const handleShowMore = () => {
    setIsSearchingMore(true);
    setPage(page + 1);
  };

  const handleOpenBookDetailModal = (event, data) => {
    setBookDetailModalData(data);
    setBookDetailModalOpen(true);
  };

  const handleOpenBookAuthorsModal = (event, data, authors) => {
    setBookDetailModalData(data);
    setBookAuthorsModalData(authors);
    setBookAuthorsModalOpen(true);
  };

  const handleOpenBookCategoriesModal = (event, data, categories) => {
    setBookDetailModalData(data);
    setBookCategoriesModalData(categories);
    setBookCategoriesModalOpen(true);
  };

  const handleOpenBookVariantsModal = (event, data, variants) => {
    setBookDetailModalData(data);
    setBookVariantsModalData(variants);
    setBookVariantsModalOpen(true);
  };

  const handleOpenImageModal = (event, image) => {
    if (image) {
      setImageModalData(image);
      setImageModalOpen(true);
    }
  };

  const handleSyncBookToShop = async (book) => {
    await syncBookToShop(accessToken, shopUuid, book.uuid);
  };

  useEffect(() => {
    const fetchBooks = async () => {
      setIsLoading(true);

      try {
        filterParams.isArchive = isArchive;

        const res = await getBooks(accessToken, shopUuid, recordsPerPage, page, filterParams);

        if (res) {
          if (res.data.data.length > 0) {
            if (isSearchingMore) {
              setBooks([...books, ...res.data.data]);
            } else {
              setBooks(res.data.data);
            }
          } else {
            setBooks([]);
            setPage(1);
          }

          setTotalResults(res.data.meta.total);
        } else {
          console.error('Error fetching books');

          setError('Nepodařilo se stáhnout seznam knih');
        }
      } catch (err) {
        console.error(err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
        setIsSearchingMore(false);
      }
    };

    fetchBooks();
  }, [accessToken, shopUuid, filterParams, page, recordsPerPage, isArchive]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await getCategories(accessToken, shopUuid);

        if (res && res.data.data.categories.length) {
          setCategories(flattenTree(formatCategoryData(res.data.data.categories[0].children)));
        } else {
          console.error('Error fetching categories');

          setError('Nepodařilo se stáhnout seznam kategorií');
        }
      } catch (err) {
        console.error(err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, [accessToken, shopUuid]);

  if (!isSearchingMore && isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item>
          <ListContentHeader
            icon={<AutoStoriesIcon />}
            title={isArchive ? 'Seznam archivovaných knih' : 'Seznam knih'}
          />
        </Grid>
        <Grid item>
          {isArchive ? (
            <BackButton
              label={'Zpět na výpis knih'}
              url={'/books'}
              sx={{ cursor: 'pointer', ml: 5, mt: 4 }}
            />
          ) : (
            <ArchiveButton
              label={'Zobrazit archiv'}
              onClick={() => navigate('/books/archive', { replace: true })}
              sx={{ cursor: 'pointer', ml: 5, mt: 4 }}
            />
          )}
        </Grid>
      </Grid>
      {error && <Alert severity="error">{error}</Alert>}
      <Box component="form" sx={{ margin: 2 }}>
        <FormControl>
          <Grid container>
            <Grid item>
              <TextField
                id="filter-term"
                placeholder="Název knihy, ISBN, autor, SKU, ..."
                variant="outlined"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ visibility: filterText ? 'visible' : 'hidden' }}
                      onClick={handleClearFilterInput}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                size={'small'}
              />
            </Grid>
            <Grid item>
              <CategorySelect
                onChange={(event, value) => handleSetCategoryFilter(value)}
                options={categories}
                // selectedValues={[]}
                selectedValues={filterCategories}
                size={'small'}
                required={false}
                sx={{ minWidth: '200px', marginLeft: 2 }}
              />
            </Grid>
            <Grid item>
              <ApplyFilterButton
                type={'submit'}
                label="Filtrovat"
                onClick={handleSubmitFilter}
                startIcon={<FilterAltIcon />}
                sx={{ ml: 1, mr: 0, mt: 0 }}
              />
              <ClearFilterButton
                label="Vymazat filtr"
                onClick={handleResetFilter}
                startIcon={<FilterAltOffIcon />}
                sx={{ marginLeft: 1, marginBottom: 1 }}
              />
            </Grid>
          </Grid>
          <FormGroup>
            <Grid container>
              <Grid item>
                <FormControlLabel
                  label="Bez výchozího obrázku"
                  control={
                    <Checkbox
                      id="filter-book-no-default-image"
                      checked={filterNoDefaultImage}
                      onChange={(event) => setFilterNoDefaultImage(event.target.checked)}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Bez vlastního obrázku"
                  control={
                    <Checkbox
                      id="filter-book-no-custom-image"
                      checked={filterNoCustomImage}
                      onChange={(event) => setFilterNoCustomImage(event.target.checked)}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Bez kategorie"
                  control={
                    <Checkbox
                      id="filter-book-no-category"
                      checked={filterNoCategory}
                      onChange={(event) => setFilterNoCategory(event.target.checked)}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Bez varianty"
                  control={
                    <Checkbox
                      id="filter-book-no-variant"
                      checked={filterNoVariant}
                      onChange={(event) => setFilterNoVariant(event.target.checked)}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Nesynchronizované"
                  control={
                    <Checkbox
                      id="filter-book-not-synced"
                      checked={filterNotSynced}
                      onChange={(event) => setFilterNotSynced(event.target.checked)}
                    />
                  }
                />
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </Box>
      {books.length > 0 ? (
        <Box>
          <Typography variant="overline" display="block" sx={{ marginLeft: 1, marginTop: 0 }}>
            {`zobrazeno ${books.length} z celkového počtu ${totalResults} ${
              Object.keys(filterParams).length ? 'nalezených ' : ''
            }knih`}
          </Typography>
          <Box sx={{ float: 'right', width: 10 }}>
            <FormControl>
              <InputLabel id="records-per-page-label">Záznamů</InputLabel>
              <Select
                labelId="records-per-page-label"
                id="records-per-page"
                value={recordsPerPage}
                label="Záznamů"
                onChange={(event) => setRecordsPerPage(event.target.value)}
                required
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={'all'}>Vše</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TableContainer component={Paper} sx={{ marginTop: 1 }}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th">Obálka</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Název</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Autor</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Vydáno</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Kategorie</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Varianty</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Cena&nbsp;od</TableCell>
                  {isArchive ? (
                    <TableCell sx={{ fontWeight: 'bold' }}>Archivováno</TableCell>
                  ) : (
                    <>
                      <TableCell sx={{ fontWeight: 'bold' }}>Sklad</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Eshop</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Poslední změna</TableCell>
                    </>
                  )}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {books.map((book) => (
                  <TableRow
                    key={book.uuid}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      td: { fontSize: '0.8rem' },
                    }}
                  >
                    <TableCell scope="row" align="center">
                      <img
                        src={book.image || NoImage}
                        alt={book.title}
                        height="60"
                        onClick={(e) => handleOpenImageModal(e, book.image)}
                        style={book.image ? { cursor: 'pointer' } : {}}
                      />
                    </TableCell>
                    <TableCell>
                      <Link
                        onClick={(e) => handleOpenBookDetailModal(e, book)}
                        sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                      >
                        {book.title}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {book.authors.length <= 0 && book.full_authors ? (
                        <>{book.full_authors}</>
                      ) : (
                        <>
                          {book.authors.length > 2 ? (
                            <>
                              {book.authors.length}&nbsp;
                              <Tooltip title="Zobrazit seznam">
                                <FindInPageOutlinedIcon
                                  fontSize="medium"
                                  sx={{ cursor: 'pointer' }}
                                  onClick={(e) => handleOpenBookAuthorsModal(e, book, book.authors)}
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              {book.authors.map((author, index) => (
                                <React.Fragment key={author.uuid}>
                                  {author.first_name} {author.last_name}
                                  {index < book.authors.length - 1 && ', '}
                                </React.Fragment>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {book.publisher
                        ? book.publisher.title
                        : book.publisher_name
                          ? book.publisher_name
                          : 'N/A'}
                      <br />
                      <i>{book.place_published}</i>
                      <br />
                      {book.year_published}
                    </TableCell>
                    <TableCell>
                      {book.categories.length > 0 && (
                        <Tooltip title="Zobrazit seznam">
                          <FindInPageOutlinedIcon
                            fontSize="medium"
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) => handleOpenBookCategoriesModal(e, book, book.categories)}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item>
                          <Box sx={{ padding: 1 }}>{book.variants.length}</Box>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Zobrazit seznam">
                            <FindInPageOutlinedIcon
                              fontSize="medium"
                              sx={{ cursor: 'pointer' }}
                              onClick={(e) => handleOpenBookVariantsModal(e, book, book.variants)}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>{book.price_from ? book.price_from + ' Kč' : 'N/A'}</TableCell>
                    {isArchive ? (
                      <TableCell>
                        {dayjs(new Date(book.deleted_at)).format('DD.MM.YYYY HH:mm:ss')}
                      </TableCell>
                    ) : (
                      <>
                        <TableCell>
                          <Typography>{book.stock_count}</Typography>&nbsp;ks
                        </TableCell>
                        <TableCell>
                          <TrueFalseIcon flag={book.published_at} />
                          {book.permalink && (
                            <IconButton component={Link} href={book.permalink} target="_blank">
                              <WebIcon />
                            </IconButton>
                          )}
                          {!book.published_at && (
                            <IconButton onClick={() => handleSyncBookToShop(book)}>
                              <SyncIcon />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell>
                          {dayjs(new Date(book.created_at)).format('DD.MM.YYYY HH:mm:ss')}
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <DetailButton
                        label={'Detail'}
                        color="primary"
                        onClick={(e) => handleOpenBookDetailModal(e, book)}
                        sx={{ cursor: 'pointer' }}
                      />
                      <EditButton
                        label={'Upravit'}
                        onClick={(e) => handleEditBook(e, book.uuid)}
                        sx={{ cursor: 'pointer' }}
                      />
                      {!book.is_archived ? (
                        <ArchiveButton
                          label={'Archivovat'}
                          onClick={(e) => handleConfirmArchiveBook(e, book.uuid)}
                          sx={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <RestoreButton
                          label={'Obnovit'}
                          onClick={(e) => handleConfirmRestoreBook(e, book.uuid)}
                          sx={{ cursor: 'pointer' }}
                        />
                      )}
                      <DeleteButton
                        label={'Smazat'}
                        onClick={(e) => handleConfirmDeleteBook(e, book.uuid)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow key={'search-more'}>
                  <TableCell colSpan={11} sx={{ alignItems: 'center', alignContent: 'center' }}>
                    {isSearchingMore && isLoading ? (
                      <SmallLoader />
                    ) : (
                      <>
                        {totalResults > books.length && (
                          <Button
                            type="button"
                            variant="contained"
                            className="btn btn-link btn-primary"
                            onClick={(e) => handleShowMore(e)}
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            Načíst další
                          </Button>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {confirmDialogValue && (
            <ConfirmationDialog
              id="confirm-dialog-archive-book"
              keepMounted
              open={openConfirmDialog}
              onClose={handleCloseConfirmDialog}
              value={confirmDialogValue}
              confirmMessage={confirmDialogMessage}
            />
          )}
          <BookDetailModal
            open={bookDetailModalOpen}
            setOpen={setBookDetailModalOpen}
            book={bookDetailModalData}
          />
          <BookAuthorsModal
            open={bookAuthorsModalOpen}
            setOpen={setBookAuthorsModalOpen}
            book={bookDetailModalData}
            authors={bookAuthorsModalData}
          />
          <BookCategoriesModal
            open={bookCategoriesModalOpen}
            setOpen={setBookCategoriesModalOpen}
            book={bookDetailModalData}
            categories={bookCategoriesModalData}
          />
          <BookVariantsModal
            accessToken={accessToken}
            shopUuid={shopUuid}
            open={bookVariantsModalOpen}
            setOpen={setBookVariantsModalOpen}
            book={bookDetailModalData}
            variants={bookVariantsModalData}
            enumGroups={enumGroups}
          />
          <ImageModal open={imageModalOpen} setOpen={setImageModalOpen} image={imageModalData} />
        </Box>
      ) : (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Nebyly nalezeny žádné knihy
            </Typography>
          </Box>
        </Container>
      )}
    </React.Fragment>
  );
};

export default BookList;
