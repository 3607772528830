import React from 'react';
import green from '../../assets/images/green.png';
import yellow from '../../assets/images/yellow.png';
import orange from '../../assets/images/orange.png';

const BookConditionCategoryIcon = ({ color, label }) => {
  const renderIcon = (image) => {
    return <img src={image} alt={label} width={20} height={20} />;
  };

  if (color === 'green') {
    return renderIcon(green);
  }

  if (color === 'yellow') {
    return renderIcon(yellow);
  }

  if (color === 'orange') {
    return renderIcon(orange);
  }

  return null;
};

export default BookConditionCategoryIcon;
