import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import ContentHeader from '../layout/ContentHeader';
import BackButton from '../utils/buttons/BackButton';
import SaveButton from '../utils/buttons/SaveButton';
import Item from '../utils/Item';
import Loader from '../utils/Loader';
import Notification from '../utils/Notification';
import { createShop, getShop, getShopTypes, updateShop } from '../../api';
import { scrollToTop } from '../../helpers';

const apiCredentialsDefault = { url: '', consumer_secret: '', consumer_key: '' };

const ShopForm = ({ accessToken, currentProjectUuid, isCreate }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [projectUuid, setProjectUuid] = useState(currentProjectUuid);
  const [shopTypeUuid, setShopTypeUuid] = useState('');
  const [companyIdentificationNumber, setCompanyIdentificationNumber] = useState('');
  const [apiCredentials, setApiCredentials] = useState(apiCredentialsDefault);
  const [isShopSyncEnabled, setIsShopSyncEnabled] = useState(false);
  const [isSandbox, setIsSandbox] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [comment, setComment] = useState('');
  const [shopTypes, setShopTypes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { uuid } = useParams();

  useEffect(() => {
    const fetchShop = async () => {
      setIsLoading(true);

      try {
        const res = await getShop(accessToken, uuid);

        if (res) {
          setTitle(res.data.title);
          setUrl(res.data.url);
          setProjectUuid(res.data.project.uuid);
          setShopTypeUuid(res.data.shop_type.uuid);
          setCompanyIdentificationNumber(res.data.company_identification_number);
          setApiCredentials(res.data.api_credentials || apiCredentialsDefault);
          setIsShopSyncEnabled(res.data.is_shop_sync_enabled);
          setIsSandbox(res.data.is_sandbox);
          setIsActive(res.data.is_active);
          setComment(res.data.comment);
        } else {
          console.error('Error fetching shop data');

          setError('Nepodařilo se získat data o obchodu');
        }
      } catch (err) {
        console.error('Fetch shop error', err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isCreate && uuid) {
      fetchShop();
    }
  }, [accessToken, uuid]);

  useEffect(() => {
    const fetchShopTypes = async () => {
      setIsLoading(true);

      try {
        const res = await getShopTypes(accessToken, uuid);

        if (res) {
          setShopTypes(res.data);
        } else {
          console.error('Error fetching shop types data');

          setError('Nepodařilo se získat seznam typů obchodů');
        }
      } catch (err) {
        console.error('Fetch shop types error', err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchShopTypes();
  }, [accessToken]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      setSuccess(false);
      setError(null);

      const shopData = {
        title,
        url,
        projectUuid,
        shopTypeUuid,
        companyIdentificationNumber,
        apiCredentials,
        isShopSyncEnabled,
        isSandbox,
        isActive,
        comment,
      };

      if (isCreate) {
        await createShop(accessToken, projectUuid, shopData);
      } else {
        await updateShop(accessToken, projectUuid, uuid, shopData);
      }

      setSuccess(true);
    } catch (err) {
      console.error(err);

      setIsLoading(false);
      setError(err.response.data.message);

      return scrollToTop();
    } finally {
      setIsLoading(false);
    }

    scrollToTop();

    setTimeout(() => {
      navigate('/shops', { replace: true });
    }, 3000);
  };

  if (isLoading) {
    return <Loader />;
  }

  const gridRowStyle = {
    p: 2,
    borderRight: '1px solid rgb(224, 224, 224);',
    borderBottom: '1px solid rgb(224, 224, 224);',
    borderLeft: '1px solid rgb(224, 224, 224);',
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ContentHeader
          title={isCreate ? 'Nový obchod' : 'Úprava obchodu'}
          navButtonBack={
            <BackButton label={'Zpět na výpis obchodů'} url={`/shops`} sx={{ float: 'left' }} />
          }
        />
        <Notification isOpen={success} severity="success" message={'Obchod byl uložen'} />
        <Notification isOpen={error} severity="error" message={error} />
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, width: { xs: '100%', sm: '100%', md: '90%', lg: '60%' } }}
        >
          <Grid container spacing={1} sx={gridRowStyle}>
            <Grid item xs={6}>
              <Stack>
                <Item>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="title"
                    label="Název"
                    id="title"
                    value={title}
                    autoComplete="title"
                    onChange={(event) => setTitle(event.target.value)}
                  />
                </Item>
                <Item>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="url"
                    label="URL adresa"
                    id="url"
                    value={url}
                    autoComplete="url"
                    onChange={(event) => setUrl(event.target.value)}
                  />
                </Item>
                <Item>
                  <FormControl fullWidth>
                    <InputLabel id="shop-type-label">Typ obchodu</InputLabel>
                    <Select
                      labelId="shop-type-label"
                      id="shop-type"
                      value={shopTypeUuid}
                      label="Typ obchodu"
                      onChange={(event) => setShopTypeUuid(event.target.value)}
                      required
                    >
                      {shopTypes.map((option) => (
                        <MenuItem key={option.uuid} value={option.uuid}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Item>
                <Item>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="company_identification_number"
                    label="IČO"
                    id="company_identification_number"
                    value={companyIdentificationNumber}
                    autoComplete="company_identification_number"
                    onChange={(event) => setCompanyIdentificationNumber(event.target.value)}
                  />
                </Item>
                <Item>
                  <FormGroup>
                    <FormLabel>{'API přístupy'}</FormLabel>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="api_url"
                      label="API url"
                      id="api_url"
                      value={apiCredentials.url}
                      onChange={(event) =>
                        setApiCredentials({ ...apiCredentials, url: event.target.value })
                      }
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="api_consumer_key"
                      label="API consumer key"
                      id="api_consumer_key"
                      value={apiCredentials.consumer_key}
                      onChange={(event) =>
                        setApiCredentials({ ...apiCredentials, consumer_key: event.target.value })
                      }
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="api_consumer_secret"
                      label="API consumer secret"
                      id="api_consumer_secret"
                      value={apiCredentials.consumer_secret}
                      onChange={(event) =>
                        setApiCredentials({
                          ...apiCredentials,
                          consumer_secret: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Item>
                <Item>
                  <TextareaAutosize
                    id="comment"
                    defaultValue={comment}
                    onChange={(event) => setComment(event.target.value)}
                    placeholder="Poznámka"
                    minRows={3}
                    required={false}
                  />
                </Item>
                <Item>
                  <SaveButton label={!isCreate ? 'Upravit obchod' : 'Uložit obchod'} />
                </Item>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ShopForm;
