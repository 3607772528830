import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import BookVariantList from './BookVariantList';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const BookVariantsModal = ({
  accessToken,
  shopUuid,
  open,
  setOpen,
  book,
  variants,
  enumGroups,
}) => {
  const handleClose = () => setOpen(false);

  const bookVariants = [];

  variants.map((variant) => {
    const variantInitialValues = {
      uuid: variant.uuid,
      shopExternalId: variant.shop_external_id,
      purchasePrice: variant.purchase_price,
      sellingPrice: variant.selling_price,
      bookBindingHasWrap: variant.book_binding_has_wrap || false,
      conditionCategory: variant.condition_category || '',
      stockCount: variant.stock_count,
      comment: variant.comment,
      image: variant.image,
      galleryImages: variant.gallery_images,
      createdAt: variant.created_at,
      updatedAt: variant.updated_at,
    };

    bookVariants.push(variantInitialValues);

    return variant;
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton onClick={handleClose} sx={{ float: 'right' }}>
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Seznam variant knihy #<b>{book.title}</b>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }} />
        <BookVariantList
          accessToken={accessToken}
          shopUuid={shopUuid}
          enumGroups={enumGroups}
          book={book}
          variants={bookVariants}
        />
      </Box>
    </Modal>
  );
};

export default BookVariantsModal;
