import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ListContentHeader from '../layout/ListContentHeader';
import AddButton from '../utils/buttons/AddButton';
import ClearFilterButton from '../utils/buttons/ClearFilterButton';
import EditButton from '../utils/buttons/EditButton';
import DeleteButton from '../utils/buttons/DeleteButton';
import ConfirmationDialog from '../utils/dialogs/ConfirmationDialog';
import WebIcon from '../utils/icons/WebIcon';
import IsActiveSwitch from '../utils/IsActiveSwitch';
import Loader from '../utils/Loader';
import { getShops, updateShop, deleteShop } from '../../api';

const ShopList = ({ accessToken, projectUuid }) => {
  const [error, setError] = useState(null);
  const [shops, setShops] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filteredShops, setFilteredShops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogValue, setConfirmDialogValue] = useState(null);
  const navigate = useNavigate();

  const handleResetFilter = () => {
    setFilterText('');
  };

  const handleChangeIsShopSyncEnabled = async (shopUuid, isShopSyncEnabled, shop) => {
    const shopData = {
      title: shop.title,
      url: shop.url,
      shopTypeUuid: shop.shop_type.uuid,
      companyIdentificationNumber: shop.company_identification_number,
      apiCredentials: shop.api_credentials ?? { url: '', consumer_secret: '', consumer_key: '' },
      isShopSyncEnabled,
      isSandbox: shop.is_sandbox,
      isActive: shop.is_active,
      comment: shop.comment,
    };

    await updateShop(accessToken, projectUuid, shopUuid, shopData);
  };

  const handleChangeIsShopSandbox = async (shopUuid, isSandbox, shop) => {
    const shopData = {
      title: shop.title,
      url: shop.url,
      shopTypeUuid: shop.shop_type.uuid,
      companyIdentificationNumber: shop.company_identification_number,
      apiCredentials: shop.api_credentials ?? { url: '', consumer_secret: '', consumer_key: '' },
      isShopSyncEnabled: shop.is_shop_sync_enabled,
      isSandbox,
      isActive: shop.is_active,
      comment: shop.comment,
    };

    await updateShop(accessToken, projectUuid, shopUuid, shopData);
  };

  const handleChangeIsShopActive = async (shopUuid, isActive, shop) => {
    const shopData = {
      title: shop.title,
      url: shop.url,
      shopTypeUuid: shop.shop_type.uuid,
      companyIdentificationNumber: shop.company_identification_number,
      apiCredentials: shop.api_credentials ?? { url: '', consumer_secret: '', consumer_key: '' },
      isShopSyncEnabled: shop.is_shop_sync_enabled,
      isSandbox: shop.is_sandbox,
      isActive,
      comment: shop.comment,
    };

    await updateShop(accessToken, projectUuid, shopUuid, shopData);
  };

  const handleCreateShop = (e) => {
    navigate('/shops/create');
  };

  const handleEditShop = (e, shopUuid) => {
    navigate(`/shops/${shopUuid}/edit`);
  };

  const handleDeleteShop = (e, shopUuid) => {
    setOpenConfirmDialog(true);
    setConfirmDialogValue(shopUuid);
  };

  const handleCloseConfirmDialog = async (shopUuid) => {
    setOpenConfirmDialog(false);
    setConfirmDialogValue(null);

    if (shopUuid) {
      await deleteShop(accessToken, shopUuid);

      const updatedShops = shops.filter((shop) => shop.uuid !== shopUuid);

      setShops(updatedShops);
    }
  };

  useEffect(() => {
    if (filterText === '') {
      setFilteredShops(shops);
    } else {
      setFilteredShops(
        filteredShops.filter((shop) => {
          return shop.title.toLowerCase().includes(filterText.toLowerCase());
        })
      );
    }
  }, [filterText]);

  useEffect(() => {
    const fetchShops = async () => {
      try {
        const res = await getShops(accessToken, projectUuid);

        if (res) {
          setShops(res.data);
          setFilteredShops(res.data);
        } else {
          console.error('Error fetching shops');

          setError('Nepodařilo se stáhnout seznam obchodů');
        }
      } catch (err) {
        console.error(err);

        if (err.response) {
          setError(err.response.message);
        }

        if (err.message) {
          setError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchShops();
  }, [accessToken]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <ListContentHeader icon={<ShoppingCartIcon />} title={'Obchody'} />
      {error && <Alert severity="error">{error}</Alert>}
      {shops.length > 0 && (
        <Box sx={{ margin: 2 }}>
          <TextField
            id="outlined-basic"
            placeholder="Název, ..."
            variant="outlined"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            size={'small'}
          />
          <ClearFilterButton
            label="Vymazat filtr"
            onClick={handleResetFilter}
            startIcon={<FilterAltOffIcon />}
            sx={{ marginLeft: 1 }}
          />
          <Typography variant="overline" display="block" sx={{ marginLeft: 1 }} gutterBottom>
            nalezeno {filteredShops.length} obchodů
          </Typography>
        </Box>
      )}
      <AddButton label={'Přidat'} variant="contained" color="success" onClick={handleCreateShop} />
      {filteredShops.length > 0 ? (
        <div>
          <TableContainer component={Paper} sx={{ marginTop: 5 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Název</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>URL</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>IČO</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Synchronizace</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Sandbox</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Aktivní</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Vytvořen</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredShops.map((shop) => (
                  <TableRow
                    key={shop.uuid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>#{shop.id}</TableCell>
                    <TableCell>{shop.title}</TableCell>
                    <TableCell>
                      <IconButton component={Link} href={shop.url} target="_blank">
                        <WebIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{shop.company_identification_number}</TableCell>
                    <TableCell>
                      <IsActiveSwitch
                        itemId={shop.uuid}
                        defaultIsActive={shop.is_shop_sync_enabled}
                        item={shop}
                        onClickSwitch={handleChangeIsShopSyncEnabled}
                      />
                    </TableCell>
                    <TableCell>
                      <IsActiveSwitch
                        itemId={shop.uuid}
                        defaultIsActive={shop.is_sandbox}
                        item={shop}
                        onClickSwitch={handleChangeIsShopSandbox}
                        successColor={'warning'}
                      />
                    </TableCell>
                    <TableCell>
                      <IsActiveSwitch
                        itemId={shop.uuid}
                        defaultIsActive={shop.is_active}
                        item={shop}
                        onClickSwitch={handleChangeIsShopActive}
                      />
                    </TableCell>
                    <TableCell>
                      {dayjs(new Date(shop.created_at)).format('DD.MM.YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      <EditButton
                        label={'Upravit'}
                        onClick={(e) => handleEditShop(e, shop.uuid)}
                        sx={{ cursor: 'pointer' }}
                      />
                      <DeleteButton
                        label={'Smazat'}
                        onClick={(e) => handleDeleteShop(e, shop.uuid)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {confirmDialogValue && (
            <ConfirmationDialog
              id="confirm-dialog-delete-shop"
              keepMounted
              open={openConfirmDialog}
              onClose={handleCloseConfirmDialog}
              value={confirmDialogValue}
              confirmMessage={'Přejete si opravdu smazat tuto položku?'}
            />
          )}
        </div>
      ) : (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Nebyly nalazeny žádné obchody
            </Typography>
          </Box>
        </Container>
      )}
    </React.Fragment>
  );
};

export default ShopList;
