import React from 'react';
import Typography from '@mui/material/Typography';
import DashboardCard from '../utils/DashboardCard';

const ShopDashboard = ({ shop, data }) => {
  const content = (
    <>
      <Typography sx={{ fontSize: 14 }} variant="body2" color="text.secondary" gutterBottom>
        {`Počet přiřazených uživatelů: ${data.users_count}`}
      </Typography>
      <Typography sx={{ fontSize: 14 }} variant="body2" color="text.secondary">
        {`Celkový počet knih v obchodě: ${data.books_count}`}
      </Typography>
      <Typography sx={{ fontSize: 14 }} variant="body2" color="text.secondary">
        {`Počet knih nevyexportovaných na eshop: ${data.books_not_published_count}`}
      </Typography>
    </>
  );

  return <DashboardCard title={`Dashboard eshopu "${shop.title}"`} content={content} />;
};

export default ShopDashboard;
