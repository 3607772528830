import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import ActionButton from './ActionButton';

export default function DeleteButton({
  label,
  onClick,
  sx,
  variant = 'contained',
  color = 'error',
}) {
  return (
    <ActionButton
      variant={variant}
      color={color}
      label={label}
      onClick={onClick}
      startIcon={<DeleteIcon />}
      sx={sx}
    />
  );
}

DeleteButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
