import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTabIndex, Untabbable } from 'react-tabindex';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import ClearFilterButton from '../utils/buttons/ClearFilterButton';
import SearchButton from '../utils/buttons/SearchButton';
import Loader from '../utils/Loader';
import SmallLoader from '../utils/SmallLoader';
import { searchLibraryBooks } from '../../api';

const LibraryBookSearchForm = ({ accessToken }) => {
  const [error, setError] = useState(null);
  const [books, setBooks] = useState([]);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [inputValueTitle, setInputValueTitle] = useState('');
  const [inputValueIsbn, setInputValueIsbn] = useState('');
  const [inputValueAuthor, setInputValueAuthor] = useState('');
  const [inputValueYearPublished, setInputValueYearPublished] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingMore, setIsSearchingMore] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const navigate = useNavigate();
  const tabIndex1 = useTabIndex(1);
  const tabIndex2 = useTabIndex(2);
  const tabIndex3 = useTabIndex(3);
  const tabIndex4 = useTabIndex(4);
  const tabIndex5 = useTabIndex(5);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setError(null);

    if (isFormEmpty()) {
      setError('Musíte vyplnit alespoň jedno pole');

      return;
    }

    handleOpenBackdrop();

    setBooks([]);
    setIsSubmitted(true);

    fetchBooks();
  };

  const handleSearchMore = (event) => {
    event.preventDefault();

    setOffset(offset + limit);
  };

  const handleClearSearchForm = () => {
    handleClearTitleInput();
    handleClearIsbnInput();
    handleClearAuthorInput();
    handleClearYearPublishedInput();

    setBooks([]);
  };

  const handleClearTitleInput = () => {
    setInputValueTitle('');
  };

  const handleClearIsbnInput = () => {
    setInputValueIsbn('');
  };

  const handleClearAuthorInput = () => {
    setInputValueAuthor('');
  };

  const handleClearYearPublishedInput = () => {
    setInputValueYearPublished('');
  };

  const handleLoadBookToForm = (event, bookData) => {
    navigate('/books/create?uuid=' + bookData.uuid, { state: { bookData } });
  };

  const handleCreateFromSearchParams = (event) => {
    event.preventDefault();

    const bookData = {
      title: inputValueTitle,
      isbn: inputValueIsbn,
      author: inputValueAuthor,
      yearPublished: inputValueYearPublished.length > 0 ? parseInt(inputValueYearPublished) : '',
    };

    navigate('/books/create', { state: { bookData } });
  };

  const isFormEmpty = () => {
    return (
      inputValueTitle.length === 0 &&
      inputValueIsbn.length === 0 &&
      inputValueAuthor.length === 0 &&
      inputValueYearPublished.length === 0
    );
  };

  const fetchBooks = async () => {
    const searchParams = {
      title: inputValueTitle,
      isbn: inputValueIsbn,
      author: inputValueAuthor,
      yearPublished: inputValueYearPublished.length > 0 ? parseInt(inputValueYearPublished) : '',
    };

    try {
      const res = await searchLibraryBooks(accessToken, searchParams, limit, offset);

      if (res) {
        const bookDataResult = res.data;

        if (isSearchingMore) {
          setBooks([...books, ...bookDataResult]);
        } else {
          setBooks(bookDataResult);
        }
      } else {
        console.error('Book not found');

        setError('Kniha nenalezena');
      }
    } catch (err) {
      console.error('Fetch Book error', err);

      setError(err.response.data.message);
    } finally {
      setIsLoading(false);
      setIsSearching(false);
      setIsSearchingMore(false);
      setOpenBackdrop(false);
    }
  };

  useEffect(() => {
    setIsSubmitted(false);
    setError(null);
  }, [inputValueTitle, inputValueIsbn, inputValueAuthor, inputValueYearPublished]);

  useEffect(() => {
    if (isFormEmpty() || limit < 10) {
      return;
    }

    setIsSearching(true);

    fetchBooks();
  }, [accessToken, limit]);

  useEffect(() => {
    if (isFormEmpty() || offset === 0) {
      return;
    }

    setIsSearchingMore(true);

    fetchBooks();
  }, [accessToken, offset]);

  // useEffect(() => {
  //   const keyDownHandler = (event) => {
  //     console.log('User pressed: ', event.key);
  //
  //     if (event.key === 'Enter') {
  //       event.preventDefault();
  //
  //       handleSearch(event);
  //     }
  //   };
  //
  //   document.addEventListener('keydown', keyDownHandler);
  //
  //   return () => {
  //     document.removeEventListener('keydown', keyDownHandler);
  //   };
  // }, []);

  const gridRowStyle = {
    p: 2,
    borderRight: '1px solid rgb(224, 224, 224);',
    borderBottom: '1px solid rgb(224, 224, 224);',
    borderLeft: '1px solid rgb(224, 224, 224);',
  };

  const renderResultsCountMessage = (count) => {
    if (count === 1) {
      return 'Nalezen 1 záznam';
    }

    if (count > 1 && count < 5) {
      return `Nalezeny ${count} záznamy`;
    }

    return `Nalezeno ${count} záznamů`;
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold', marginTop: 5 }}>
          Vyhledávání knih v NKP
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <Box component="form" sx={{ mt: 1 }}>
          <FormControl>
            <Grid container spacing={1} sx={gridRowStyle}>
              <Grid item xs={4}>
                <FormLabel component="legend">Počet výsledků</FormLabel>
                <Select
                  value={limit}
                  onChange={(event) => setLimit(event.target.value)}
                  sx={{ minWidth: 120 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={8}>
                {renderResultsCountMessage(books.length)}
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={gridRowStyle}>
              <Grid item xs={3}>
                <TextField
                  label="Název knihy"
                  placeholder="Název knihy"
                  variant="outlined"
                  value={inputValueTitle}
                  autoFocus={true}
                  onChange={(event) => setInputValueTitle(event.target.value)}
                  tabIndex={tabIndex1}
                  InputProps={{
                    endAdornment: (
                      <Untabbable>
                        <IconButton
                          sx={{ visibility: inputValueTitle ? 'visible' : 'hidden' }}
                          onClick={handleClearTitleInput}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Untabbable>
                    ),
                  }}
                  sx={{
                    m: 2,
                    '& .Mui-focused .MuiIconButton-root': { color: 'primary.main' },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Autor"
                  placeholder="Autor"
                  variant="outlined"
                  value={inputValueAuthor}
                  onChange={(event) => setInputValueAuthor(event.target.value)}
                  tabIndex={tabIndex3}
                  InputProps={{
                    endAdornment: (
                      <Untabbable>
                        <IconButton
                          sx={{ visibility: inputValueAuthor ? 'visible' : 'hidden' }}
                          onClick={handleClearAuthorInput}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Untabbable>
                    ),
                  }}
                  sx={{
                    m: 2,
                    '& .Mui-focused .MuiIconButton-root': { color: 'primary.main' },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="ISBN"
                  placeholder="ISBN"
                  variant="outlined"
                  value={inputValueIsbn}
                  onChange={(event) => setInputValueIsbn(event.target.value)}
                  tabIndex={tabIndex2}
                  InputProps={{
                    endAdornment: (
                      <Untabbable>
                        <IconButton
                          sx={{ visibility: inputValueIsbn ? 'visible' : 'hidden' }}
                          onClick={handleClearIsbnInput}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Untabbable>
                    ),
                  }}
                  sx={{
                    m: 2,
                    '& .Mui-focused .MuiIconButton-root': { color: 'primary.main' },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Rok vydání"
                  placeholder="Rok vydání"
                  variant="outlined"
                  value={inputValueYearPublished}
                  onChange={(event) => setInputValueYearPublished(event.target.value)}
                  tabIndex={tabIndex4}
                  InputProps={{
                    endAdornment: (
                      <Untabbable>
                        <IconButton
                          sx={{ visibility: inputValueYearPublished ? 'visible' : 'hidden' }}
                          onClick={handleClearYearPublishedInput}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Untabbable>
                    ),
                  }}
                  sx={{
                    m: 2,
                    '& .Mui-focused .MuiIconButton-root': { color: 'primary.main' },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={gridRowStyle}>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <SearchButton
                  label={'Vyhledat'}
                  onClick={handleSearch}
                  tabIndex={tabIndex5}
                  sx={{ mr: 2 }}
                />
                <Untabbable>
                  <ClearFilterButton
                    label={'Smazat'}
                    onClick={handleClearSearchForm}
                    sx={{ margin: 1 }}
                  />
                </Untabbable>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </FormControl>
        </Box>
        <Box sx={{ mt: 1, minWidth: 650 }}>
          {isLoading && <Loader />}
          {!isLoading && books.length > 0 && (
            <TableContainer component={Paper} sx={{ marginTop: 5 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Název</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>ISBN</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Autor</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Jazyk</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Nakladatelství</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Místo</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Rok vydání</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Číslo vydání</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {books.map((book) => (
                    <TableRow
                      key={book.uuid}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{book.title}</TableCell>
                      <TableCell>{book.isbn}</TableCell>
                      <TableCell>
                        {book.authors.length > 0 ? (
                          <ul>
                            {book.authors.map((author) => (
                              <li key={author.uuid}>
                                {author.first_name} {author.last_name}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <span>{book.full_authors}</span>
                        )}
                      </TableCell>
                      <TableCell>{book.language}</TableCell>
                      <TableCell>{book.publisher_name}</TableCell>
                      <TableCell>{book.place_published}</TableCell>
                      <TableCell>{book.year_published}</TableCell>
                      <TableCell>{book.publishing_number}</TableCell>
                      <TableCell align="right">
                        <Button
                          type="button"
                          variant="outlined"
                          className="btn btn-link"
                          onClick={(e) => handleLoadBookToForm(e, book)}
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          Načíst
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {isSearchingMore ? (
                    <SmallLoader />
                  ) : (
                    <>
                      {books.length >= limit + offset && (
                        <TableRow>
                          <TableCell
                            colSpan={9}
                            sx={{ alignItems: 'center', alignContent: 'center' }}
                          >
                            <Button
                              type="button"
                              variant="contained"
                              className="btn btn-link btn-primary"
                              onClick={(e) => handleSearchMore(e)}
                              sx={{ whiteSpace: 'nowrap' }}
                            >
                              Načíst další
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {isSubmitted &&
            !isLoading &&
            !isSearching &&
            !openBackdrop &&
            books.length === 0 &&
            !isFormEmpty() && (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="warning">
                  <AlertTitle>
                    <strong>Nenalezeny žádné výsledky</strong>
                    <Button
                      type="button"
                      variant="contained"
                      className="btn btn-link btn-primary"
                      onClick={(e) => handleCreateFromSearchParams(e)}
                      sx={{ whiteSpace: 'nowrap', marginLeft: 2 }}
                    >
                      Předvyplnit novou knihu z hledaných parametrů
                    </Button>
                  </AlertTitle>
                </Alert>
              </Stack>
            )}
        </Box>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default LibraryBookSearchForm;
