import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader() {
  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ display: 'flex', margin: '40%' }}>
        <CircularProgress />
      </Box>
    </Container>
  );
}
