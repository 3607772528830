import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import { useFormik } from 'formik';
import 'dayjs/locale/cs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import BookConditionCategoryIcon from './BookConditionCategoryIcon';
import ContentHeader from '../layout/ContentHeader';
import BackButton from '../utils/buttons/BackButton';
import SaveButton from '../utils/buttons/SaveButton';
import QuantityBox from '../utils/QuantityBox';
import Item from '../utils/Item';
import Loader from '../utils/Loader';
import { scrollToTop } from '../../helpers';
import { createBookVariant, updateBookVariant, uploadBookVariantImage } from '../../api';
import NoImage from '../../assets/images/noimage.jpg';

const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG'];

const BookVariantForm = ({
  accessToken,
  shopUuid,
  enumGroups,
  isCreate,
  initialData,
  initError = null,
}) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(initError);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isLoading = isSubmitting;
  const navigate = useNavigate();

  const { CONDITION_CATEGORY_OPTIONS } = enumGroups;

  const { uuid: bookUuid, variantUuid } = useParams();

  const [bookVariantImageUrl, setBookVariantImageUrl] = useState(initialData.image || null);
  const [uploadedBookVariantImage, setUploadedBookVariantImage] = useState(null);

  const [bookVariantGalleryImagesUrls, setBookVariantGalleryImagesUrls] = useState(
    initialData.galleryImages || []
  );
  const [uploadedBookVariantGalleryImages, setUploadedBookVariantGalleryImages] = useState([]);

  const formik = useFormik({
    initialValues: initialData,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const resetForm = async () => {
    await formik.resetForm();
  };

  const setNumericValue = (event, fieldName) => {
    event.preventDefault();

    const regex = /^[0-9\b]+$/;

    if (regex.test(event.target.value)) {
      formik.setFieldValue(fieldName, parseInt(event.target.value));
    }
  };

  const handleDraggedFileUploadChange = async (file) => {
    setUploadedBookVariantImage(file);
    setBookVariantImageUrl(URL.createObjectURL(file));
  };

  const handleDraggedFileUploadGalleryChange = async (file) => {
    const uploadedFile = file[0];

    setUploadedBookVariantGalleryImages([...uploadedBookVariantGalleryImages, uploadedFile]);
    setBookVariantGalleryImagesUrls([
      ...bookVariantGalleryImagesUrls,
      URL.createObjectURL(uploadedFile),
    ]);
  };

  const validateForm = () => {
    const errors = {};

    return errors;
  };

  const handleImageUpload = async (event, uploadedImageVariantUuid, uploadedImage, imageType) => {
    try {
      const fd = new FormData();
      fd.append('image', uploadedImage);

      await uploadBookVariantImage(
        accessToken,
        shopUuid,
        bookUuid,
        uploadedImageVariantUuid,
        fd,
        imageType
      );
    } catch (err) {
      console.error('Book variant image upload error', err);

      if (err.response && err.response.data && err.response.data.message) {
        setError(
          `Chyba při nahrání obrázku: ${err.response.data.message}. Zkuste to prosím znovu.`
        );
      } else {
        setError(`Chyba při nahrání obrázku. Zkuste to prosím znovu.`);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(null);
    setSuccess(false);
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setError(Object.values(formErrors)[0]);

      return scrollToTop();
    }

    if (errorMessage !== null) {
      setError(errorMessage);
      setErrorMessage(null);

      return scrollToTop();
    }

    setIsSubmitting(true);

    const bookVariantData = {
      purchasePrice: formik.values.purchasePrice,
      sellingPrice: formik.values.sellingPrice,
      bookBindingHasWrap: formik.values.bookBindingHasWrap,
      conditionCategory: formik.values.conditionCategory,
      stockCount: formik.values.stockCount,
      rating: formik.values.rating,
      comment: formik.values.comment,
      commentInternal: formik.values.commentInternal,
    };

    try {
      let uploadImageVariantUuid;

      if (isCreate) {
        const res = await createBookVariant(accessToken, shopUuid, bookUuid, bookVariantData);

        uploadImageVariantUuid = res.data.uuid;
      } else {
        await updateBookVariant(accessToken, shopUuid, bookUuid, variantUuid, bookVariantData);

        uploadImageVariantUuid = variantUuid;
      }

      await resetForm();

      setSuccess(true);

      if (uploadedBookVariantImage !== null) {
        await handleImageUpload(event, uploadImageVariantUuid, uploadedBookVariantImage, 'main');
      }

      if (uploadedBookVariantGalleryImages.length > 0) {
        uploadedBookVariantGalleryImages.map(async (image) => {
          await handleImageUpload(event, uploadImageVariantUuid, image, 'gallery');
        });
      }
    } catch (err) {
      console.error('Book variant create error', err);

      if (err.response && err.response.data && err.response.data.message) {
        setError(
          `Chyba při odeslání formuláře: ${err.response.data.message}. Zkontrolujte prosím formulář a zkuste to znovu.`
        );
      } else {
        setError(`Chyba při odeslání formuláře. Zkontrolujte prosím formulář a zkuste to znovu.`);
      }

      return scrollToTop();
    } finally {
      setIsSubmitting(false);
    }

    scrollToTop();

    setTimeout(() => {
      navigate(`/books/${bookUuid}/edit#variants`, { replace: true });
    }, 3000);
  };

  const gridRowStyle = {
    p: 2,
    borderRight: '1px solid rgb(224, 224, 224);',
    borderBottom: '1px solid rgb(224, 224, 224);',
    borderLeft: '1px solid rgb(224, 224, 224);',
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ContentHeader
          title={!isCreate ? 'Úprava varianty' : 'Nová varianta'}
          navButtonBack={
            !isCreate && (
              <BackButton
                label={'Zpět na varianty'}
                url={`/books/${bookUuid}/edit#variants`}
                sx={{ float: 'left' }}
              />
            )
          }
        />
        {success && <Alert severity="success">Varianta knihy byla úspěšně uložena!</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        {!success && (
          <Box component="form" onSubmit={handleSubmit}>
            <FormControl sx={{ width: '100%' }}>
              <Grid container spacing={1} sx={gridRowStyle}>
                <Grid item xs={6}>
                  <Stack>
                    <Item>
                      <FormControl fullWidth sx={{ marginLeft: 2 }}>
                        <FormControlLabel
                          label="Kniha má přebal"
                          control={
                            <Checkbox
                              id="book_binding_has_wrap"
                              checked={formik.values.bookBindingHasWrap}
                              onChange={(event) =>
                                formik.setFieldValue('bookBindingHasWrap', event.target.checked)
                              }
                            />
                          }
                        />
                      </FormControl>
                    </Item>
                    <Item>
                      <FormControl fullWidth>
                        <InputLabel id="condition-category-label">Stav</InputLabel>
                        <Select
                          labelId="condition-category-label"
                          id="condition_category"
                          value={formik.values.conditionCategory}
                          label="Stav"
                          onChange={(event) =>
                            formik.setFieldValue('conditionCategory', event.target.value)
                          }
                          renderValue={(selected) => {
                            const selectedOption = CONDITION_CATEGORY_OPTIONS.find(
                              (option) => option.value === selected
                            );

                            return (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <BookConditionCategoryIcon
                                  color={selectedOption.color}
                                  label={selectedOption.label}
                                />
                                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                                  {selectedOption.label}
                                </Typography>
                              </Box>
                            );
                          }}
                          required
                        >
                          {CONDITION_CATEGORY_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              <ListItemIcon>
                                <BookConditionCategoryIcon
                                  color={option.color}
                                  label={option.label}
                                />
                              </ListItemIcon>
                              <ListItemText primary={option.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Item>
                    <Item>
                      <TextField
                        variant="outlined"
                        label="Nákupní cena"
                        id="purchase_price"
                        type="number"
                        value={formik.values.purchasePrice}
                        onChange={(event) => setNumericValue(event, 'purchasePrice')}
                        size="small"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">Kč</InputAdornment>,
                        }}
                      />
                    </Item>
                    <Item>
                      <TextField
                        label="Prodejní cena"
                        id="selling_price"
                        type="number"
                        value={formik.values.sellingPrice}
                        onChange={(event) => setNumericValue(event, 'sellingPrice')}
                        size="small"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">Kč</InputAdornment>,
                        }}
                        required
                      />
                    </Item>
                    <Item>
                      <Grid container spacing={1} sx={gridRowStyle}>
                        <Grid item xs={4}>
                          <label htmlFor="stock_count" id="stock_count_label">
                            Skladem (ks):{' '}
                          </label>
                        </Grid>
                        <Grid item xs={8}>
                          <QuantityBox
                            label="Skladem (ks)"
                            id="stock_count"
                            labelId="boxCountLabel"
                            initialValue={formik.values.stockCount}
                            minimumValue={isCreate ? 1 : 0}
                            onChange={(value) => formik.setFieldValue('stockCount', value)}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Item>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Item sx={{ justifyContent: 'center', alignItems: 'center' }}>
                      <FileUploader
                        handleChange={handleDraggedFileUploadChange}
                        name="file"
                        types={fileTypes}
                        label="Nahrajte nebo přetáhněte obrázek"
                        multiple={false}
                      />
                      <Box
                        component="img"
                        sx={{
                          padding: 1,
                          maxWidth: { xs: 200, md: 250 },
                          maxHeight: { xs: 200, md: 250 },
                        }}
                        alt={initialData.title || 'Náhled'}
                        src={bookVariantImageUrl || NoImage}
                      />
                      <FileUploader
                        handleChange={handleDraggedFileUploadGalleryChange}
                        name="file"
                        types={fileTypes}
                        label="Nahrajte nebo přetáhněte obrázek"
                        multiple={true}
                      />
                      <Grid container>
                        {bookVariantGalleryImagesUrls.map((galleryImageUrl, index) => (
                          <Grid item xs={6} md={4} key={index}>
                            <Box
                              component="img"
                              sx={{
                                padding: 1,
                                maxWidth: { xs: 50, md: 75 },
                                maxHeight: { xs: 50, md: 75 },
                                border: '1px solid #e0e0e0',
                              }}
                              alt={'Náhled'}
                              src={galleryImageUrl}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Item>
                    <Item>
                      <TextareaAutosize
                        id="comment"
                        defaultValue={formik.values.comment}
                        onChange={(event) => formik.setFieldValue('comment', event.target.value)}
                        placeholder="Poznámka ke knize"
                        minRows={3}
                        sx={{ width: '100%' }}
                      />
                    </Item>
                    <Item>
                      <TextareaAutosize
                        id="comment_internal"
                        defaultValue={formik.values.commentInternal}
                        onChange={(event) =>
                          formik.setFieldValue('commentInternal', event.target.value)
                        }
                        placeholder="Interní poznámka"
                        minRows={3}
                        sx={{ width: '100%' }}
                      />
                    </Item>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={gridRowStyle}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <SaveButton
                    label={bookUuid && !isCreate ? 'Upravit variantu' : 'Uložit variantu'}
                    variant="contained"
                    color="success"
                    type="submit"
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default BookVariantForm;
