import axios from 'axios';
import { API_BASE_URL } from './config';

const getConfig = (accessToken) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  };
};

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  // timeout: 50000,
});

export async function login(username, password) {
  return await axiosInstance.post(`/login`, { email: username, password });
}

export async function register(username, password) {
  return await axiosInstance.post(`/registration`, { email: username, password });
}

export async function passwordReset(email) {
  return await axiosInstance.post(`/password-reset`, { email });
}

export async function getRoles(accessToken) {
  return await axiosInstance.get(`/roles`, getConfig(accessToken));
}

export async function getUsers(accessToken, shopUuid) {
  return await axiosInstance.get(`/shops/${shopUuid}/users`, getConfig(accessToken));
}

export async function getUser(accessToken, shopUuid, userUuid) {
  return await axiosInstance.get(`/shops/${shopUuid}/users/${userUuid}`, getConfig(accessToken));
}

export async function createUser(accessToken, shopUuid, userData) {
  return await axiosInstance.post(
    `/shops/${shopUuid}/users`,
    { ...userData, roles: [userData.userRoles] },
    getConfig(accessToken)
  );
}

export async function updateUser(accessToken, shopUuid, userUuid, userData) {
  return await axiosInstance.put(
    `/shops/${shopUuid}/users/${userUuid}`,
    { ...userData, roles: [userData.userRoles] },
    getConfig(accessToken)
  );
}

export async function deleteUser(accessToken, shopUuid, userUuid) {
  return await axiosInstance.delete(`/shops/${shopUuid}/users/${userUuid}`, getConfig(accessToken));
}

export async function getLoggedUser(accessToken) {
  return await axiosInstance.get(`/user/account`, getConfig(accessToken));
}

export async function updateLoggedUser(accessToken, password, passwordNew) {
  return await axiosInstance.put(
    `/user/account`,
    {
      password,
      password_new: passwordNew,
      password_new_confirmation: passwordNew,
    },
    getConfig(accessToken)
  );
}

export async function getLoggedUserDashboard(accessToken) {
  return await axiosInstance.get(`/user/dashboard`, getConfig(accessToken));
}

export async function searchLibraryAuthors(accessToken, searchTerm) {
  return await axiosInstance.get(
    `/library/authors/search?query=${searchTerm}`,
    getConfig(accessToken)
  );
}

export async function searchLibraryPublishers(accessToken, searchTerm) {
  return await axiosInstance.get(
    `/library/publishers/search?query=${searchTerm}`,
    getConfig(accessToken)
  );
}

export async function searchLibraryBooks(accessToken, searchParams, limit, offset) {
  const stringParams = Object.keys(searchParams)
    .map((key) => `query[${key}]=${searchParams[key]}`)
    .join('&');

  return await axiosInstance.get(
    `/library/books/search?${stringParams}&limit=${limit}&offset=${offset}`,
    getConfig(accessToken)
  );
  // return await axiosInstance.get(`/library/books/search`, {
  //     query: searchTerm,
  //     limit: limit,
  //     offset: offset,
  // }, getConfig(accessToken));
}

export async function getLibraryBookImage(accessToken, libraryBookUuid) {
  return await axiosInstance.get(`/library/books/${libraryBookUuid}/image`, getConfig(accessToken));
}

export async function getShopTypes(accessToken) {
  return await axiosInstance.get(`/shop-types`, getConfig(accessToken));
}

export async function getProjects(accessToken) {
  return await axiosInstance.get(`/projects`, getConfig(accessToken));
}

export async function getProjectDashboard(accessToken, projectUuid) {
  return await axiosInstance.get(`/projects/${projectUuid}/dashboard`, getConfig(accessToken));
}

export async function getShops(accessToken, projectUuid) {
  return await axiosInstance.get(`/shops?project=${projectUuid}`, getConfig(accessToken));
}

export async function getShopDashboard(accessToken, shopUuid) {
  return await axiosInstance.get(`/shops/${shopUuid}/dashboard`, getConfig(accessToken));
}

export async function getShop(accessToken, shopUuid) {
  return await axiosInstance.get(`/shops/${shopUuid}`, getConfig(accessToken));
}

export async function createShop(accessToken, projectUuid, shopData) {
  const {
    shopTypeUuid,
    title,
    url,
    companyIdentificationNumber,
    apiCredentials,
    isShopSyncEnabled,
    isSandbox,
    isActive,
    comment,
  } = shopData;

  return await axiosInstance.post(
    `/shops`,
    {
      project_uuid: projectUuid,
      shop_type_uuid: shopTypeUuid,
      title,
      url,
      company_identification_number: companyIdentificationNumber,
      api_credentials: apiCredentials,
      is_shop_sync_enabled: isShopSyncEnabled,
      is_sandbox: isSandbox,
      is_active: isActive,
      comment,
    },
    getConfig(accessToken)
  );
}

export async function updateShop(accessToken, projectUuid, shopUuid, shopData) {
  const {
    shopTypeUuid,
    title,
    url,
    companyIdentificationNumber,
    apiCredentials,
    isShopSyncEnabled,
    isSandbox,
    isActive,
    comment,
  } = shopData;

  return await axiosInstance.put(
    `/shops/${shopUuid}`,
    {
      project_uuid: projectUuid,
      shop_type_uuid: shopTypeUuid,
      title,
      url,
      company_identification_number: companyIdentificationNumber,
      api_credentials: apiCredentials,
      is_shop_sync_enabled: isShopSyncEnabled,
      is_sandbox: isSandbox,
      is_active: isActive,
      comment,
    },
    getConfig(accessToken)
  );
}

export async function deleteShop(accessToken, shopUuid) {
  return await axiosInstance.delete(`/shops/${shopUuid}`, getConfig(accessToken));
}

export async function getAuthors(accessToken) {
  return await axiosInstance.get(`/authors`, getConfig(accessToken));
}

export async function getCategories(accessToken, shopUuid) {
  return await axiosInstance.get(`/shops/${shopUuid}/categories`, getConfig(accessToken));
}

export async function getCategory(accessToken, shopUuid, categoryUuid) {
  return await axiosInstance.get(
    `/shops/${shopUuid}/categories/${categoryUuid}`,
    getConfig(accessToken)
  );
}

export async function createCategory(accessToken, shopUuid, categoryData) {
  const { title, slug, description, isActive } = categoryData;

  return await axiosInstance.post(
    `/shops/${shopUuid}/categories`,
    {
      title,
      slug,
      description,
      is_active: isActive,
    },
    getConfig(accessToken)
  );
}

export async function updateCategory(accessToken, shopUuid, categoryUuid, categoryData) {
  const { title, slug, description, isActive } = categoryData;

  return await axiosInstance.put(
    `/shops/${shopUuid}/categories/${categoryUuid}`,
    {
      title,
      slug,
      description,
      is_active: isActive,
    },
    getConfig(accessToken)
  );
}

export async function getEnums(accessToken, shopUuid) {
  return await axiosInstance.get(`/shops/${shopUuid}/enums`, getConfig(accessToken));
}

export async function getOrders(accessToken, shopUuid, perPage = 20, page = 1, filterParams = {}) {
  const params = Object.keys(filterParams).map((key) => `filters[${key}]=${filterParams[key]}`);

  params.push(`sort=created_at`, `per_page=${perPage}`, `page=${page}`);

  return await axiosInstance.get(
    `/shops/${shopUuid}/orders?${params.join('&')}`,
    getConfig(accessToken)
  );
}

export async function getBooks(accessToken, shopUuid, perPage = 20, page = 1, filterParams = {}) {
  const params = Object.keys(filterParams).map((key) =>
    createQueryStringParam(key, filterParams[key])
  );

  params.push(`sort=created_at`, `per_page=${perPage}`, `page=${page}`);

  return await axiosInstance.get(
    `/shops/${shopUuid}/books?${params.join('&')}`,
    getConfig(accessToken)
  );
}

function createQueryStringParam(paramName, paramValue) {
  if (Array.isArray(paramValue)) {
    return paramValue.map((value) => `filters[${paramName}][]=${value}`).join('&');
  }

  return `filters[${paramName}]=${paramValue}`;
}

export async function getBook(accessToken, shopUuid, bookUuid) {
  return await axiosInstance.get(`/shops/${shopUuid}/books/${bookUuid}`, getConfig(accessToken));
}

export async function getBookVariant(accessToken, shopUuid, bookUuid, variantUuid) {
  return await axiosInstance.get(
    `/shops/${shopUuid}/books/${bookUuid}/variants/${variantUuid}`,
    getConfig(accessToken)
  );
}

export async function getBookLog(accessToken, shopUuid, bookUuid) {
  return await axiosInstance.get(
    `/shops/${shopUuid}/books/${bookUuid}/log`,
    getConfig(accessToken)
  );
}

export async function createBook(accessToken, shopUuid, bookData) {
  const {
    libraryBookUuid,
    authors,
    categories,
    title,
    slug,
    summary,
    isbn,
    publisher,
    publisherName,
    placePublished,
    yearPublished,
    publishingNumber,
    pageCount,
    edition,
    format,
    bookBinding,
    language,
    rating,
    comment,
  } = bookData;

  return await axiosInstance.post(
    `/shops/${shopUuid}/books`,
    {
      library_book_uuid: libraryBookUuid,
      library_publisher_uuid: publisher?.uuid || null,
      authors,
      categories,
      title,
      slug,
      summary,
      isbn,
      page_count: pageCount,
      publisher_name: publisherName,
      place_published: placePublished,
      year_published: yearPublished,
      publishing_number: publishingNumber,
      edition: edition + '',
      format,
      book_binding: bookBinding,
      language,
      rating,
      comment,
    },
    getConfig(accessToken)
  );
}

export async function updateBook(accessToken, shopUuid, bookUuid, bookData) {
  const {
    authors,
    categories,
    title,
    slug,
    summary,
    isbn,
    publisher,
    publisherName,
    placePublished,
    yearPublished,
    publishingNumber,
    pageCount,
    edition,
    format,
    bookBinding,
    language,
    rating,
    comment,
  } = bookData;

  return await axiosInstance.put(
    `/shops/${shopUuid}/books/${bookUuid}`,
    {
      library_publisher_uuid: publisher?.uuid || null,
      authors,
      categories,
      title,
      slug,
      summary,
      isbn,
      page_count: pageCount,
      publisher_name: publisherName,
      place_published: placePublished,
      year_published: yearPublished,
      publishing_number: publishingNumber,
      edition: edition + '',
      format,
      book_binding: bookBinding,
      language,
      rating,
      comment,
    },
    getConfig(accessToken)
  );
}

export async function deleteBook(accessToken, shopUuid, bookUuid, forceDelete = false) {
  return await axiosInstance.delete(
    `/shops/${shopUuid}/books/${bookUuid}?force_delete=${forceDelete}`,
    getConfig(accessToken)
  );
}

export async function restoreBook(accessToken, shopUuid, bookUuid) {
  return await axiosInstance.put(
    `/shops/${shopUuid}/books/${bookUuid}/restore`,
    getConfig(accessToken)
  );
}

export async function uploadBookImage(accessToken, shopUuid, bookUuid, image) {
  return await axiosInstance.post(
    `/shops/${shopUuid}/books/${bookUuid}/images`,
    image,
    getConfig(accessToken)
  );
}

export async function syncBookToShop(accessToken, shopUuid, bookUuid) {
  return await axiosInstance.post(
    `/shops/${shopUuid}/books/${bookUuid}/sync`,
    {},
    getConfig(accessToken)
  );
}

export async function createBookVariant(accessToken, shopUuid, bookUuid, bookVariantData) {
  const {
    purchasePrice,
    sellingPrice,
    bookBindingHasWrap,
    conditionCategory,
    stockCount,
    comment,
    commentInternal,
  } = bookVariantData;

  return await axiosInstance.post(
    `/shops/${shopUuid}/books/${bookUuid}/variants`,
    {
      purchase_price: purchasePrice,
      selling_price: sellingPrice,
      book_binding_has_wrap: bookBindingHasWrap ? 1 : 0,
      condition_category: conditionCategory,
      stock_count: stockCount,
      comment,
      comment_internal: commentInternal,
    },
    getConfig(accessToken)
  );
}

export async function updateBookVariant(
  accessToken,
  shopUuid,
  bookUuid,
  bookVariantUuid,
  bookVariantData
) {
  const {
    purchasePrice,
    sellingPrice,
    bookBindingHasWrap,
    conditionCategory,
    stockCount,
    comment,
    commentInternal,
  } = bookVariantData;

  return await axiosInstance.put(
    `/shops/${shopUuid}/books/${bookUuid}/variants/${bookVariantUuid}`,
    {
      purchase_price: purchasePrice,
      selling_price: sellingPrice,
      book_binding_has_wrap: bookBindingHasWrap ? 1 : 0,
      condition_category: conditionCategory,
      stock_count: stockCount,
      comment,
      comment_internal: commentInternal,
    },
    getConfig(accessToken)
  );
}

export async function deleteBookVariant(accessToken, shopUuid, bookUuid, bookVariantUuid) {
  return await axiosInstance.delete(
    `/shops/${shopUuid}/books/${bookUuid}/variants/${bookVariantUuid}`,
    getConfig(accessToken)
  );
}

export async function uploadBookVariantImage(
  accessToken,
  shopUuid,
  bookUuid,
  bookVariantUuid,
  image,
  imageType = 'main'
) {
  return await axiosInstance.post(
    `/shops/${shopUuid}/books/${bookUuid}/variants/${bookVariantUuid}/images?image_type=${imageType}`,
    image,
    getConfig(accessToken)
  );
}

export async function syncBookVariantToShop(accessToken, shopUuid, bookUuid, variantUuid) {
  return await axiosInstance.post(
    `/shops/${shopUuid}/books/${bookUuid}/variants/${variantUuid}/sync`,
    {},
    getConfig(accessToken)
  );
}

export async function downloadFile(accessToken, fileCode) {
  const config = getConfig(accessToken);

  return await axiosInstance.get(`/files/${fileCode}`, {
    ...config,
    responseType: 'blob',
  });
}
