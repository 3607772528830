import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import 'dayjs/locale/cs';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Rating from '@mui/material/Rating';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { FileUploader } from 'react-drag-drop-files';
import CategorySelect from './CategorySelect';
import AddButton from '../utils/buttons/AddButton';
import SaveButton from '../utils/buttons/SaveButton';
import Item from '../utils/Item';
import Loader from '../utils/Loader';
import InputAutocomplete from '../utils/InputAutocomplete';
import MultiSelectAutocomplete from '../utils/MultiSelectAutocomplete';
import SmallLoader from '../utils/SmallLoader';
import {
  createBook,
  updateBook,
  uploadBookImage,
  getCategories,
  searchLibraryAuthors,
  searchLibraryPublishers,
  getLibraryBookImage,
} from '../../api';
import { createSlug, formatCategoryData, flattenTree, scrollToTop } from '../../helpers';
import NoImage from '../../assets/images/noimage.jpg';

const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG'];

const BookBasicInfoForm = ({ accessToken, shopUuid, enumGroups, isCreate, initialData }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [authors, setAuthors] = useState([]);
  const [authorsSearchTerm, setAuthorsSearchTerm] = useState('');
  const [publishers, setPublishers] = useState([]);
  const [publishersSearchTerm, setPublishersSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const [bookImageUrl, setBookImageUrl] = useState(initialData.image || null);
  const [uploadedBookImage, setUploadedBookImage] = useState(null);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [isAuthorsLoading, setIsAuthorsLoading] = useState(false);
  const [isPublishersLoading, setIsPublishersLoading] = useState(false);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);
  const [isBookImageLoading, setIsBookImageLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isLoading = isCategoriesLoading || isSubmitting;

  const { FORMAT_OPTIONS, BOOK_BINDING_OPTIONS, LANGUAGE_OPTIONS } = enumGroups;

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const bookUuid = initialData.uuid || null;
  const libraryBookUuid = initialData.libraryBookUuid || null;

  const isCreateFromLibraryBook = isCreate && libraryBookUuid !== null;
  const isEdit = !isCreate && bookUuid !== null;

  const navigate = useNavigate();

  const handleNavigateToCreateVariant = (event, variantBookUuid) => {
    event.preventDefault();

    return navigate(`/books/${variantBookUuid}/variants/create`);
  };

  const getAuthorFullName = (authorData) => {
    return authorData.first_name + ' ' + authorData.last_name;
  };

  const formatAuthorData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.uuid,
        label: getAuthorFullName(row),
      };
    });
  };

  const formatPublisherData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.uuid,
        label: row.title,
      };
    });
  };

  const setCategory = (event, value) => {
    formik.setFieldValue('categories', { ...categories, ...value });
  };

  const resetForm = async () => {
    await formik.resetForm();
  };

  const setNumericValue = (event, fieldName, allowedCharacters = '') => {
    event.preventDefault();

    const regex = /^[0-9+\b]+$/;
    // const regex = new RegExp('/^[0-9' + allowedCharacters + '\b]+$/');

    if (regex.test(event.target.value)) {
      formik.setFieldValue(fieldName, event.target.value);
    }
  };

  const updateAuthorsSearchTerm = (term) => {
    setAuthorsSearchTerm(term);
  };

  const handleSetTitle = (event, value) => {
    event.preventDefault();

    formik.setFieldValue('title', value);

    handleSetSlug(event, value);
  };

  const handleSetSlug = (event, value) => {
    event.preventDefault();

    if (value && value !== '') {
      formik.setFieldValue('slug', createSlug(value));
    } else {
      formik.setFieldValue('slug', '');
    }
  };

  const handleSetAuthors = (event, value) => {
    event.preventDefault();

    formik.setFieldValue('authors', value);
  };

  const handleSetPublisher = (event, value) => {
    event.preventDefault();

    if (value) {
      formik.setFieldValue('publisher', value);
      formik.setFieldValue('publisherName', value.title);

      if (formik.values.placePublished === null || formik.values.placePublished === '') {
        formik.setFieldValue('placePublished', value.city);
      }
    } else {
      formik.setFieldValue('publisher', null);
      formik.setFieldValue('publisherName', '');
    }
  };

  const handleSetPublisherName = (value) => {
    formik.setFieldValue('publisherName', value);
  };

  const handleDraggedFileUploadChange = async (file) => {
    setUploadedBookImage(file);
    setBookImageUrl(URL.createObjectURL(file));
  };

  useEffect(() => {
    const fetchLibraryBookImage = async () => {
      try {
        setIsBookImageLoading(true);

        const res = await getLibraryBookImage(accessToken, libraryBookUuid);

        setBookImageUrl(res.data.image);
        setUploadedBookImage(null);

        setError(null);
      } catch (err) {
        console.error(err);

        if (err.response && err.response.message) {
          setError(err.response.message);
        }
      } finally {
        setIsBookImageLoading(false);
      }
    };

    if (isCreate && libraryBookUuid !== null) {
      fetchLibraryBookImage();
    }
  }, [accessToken, isCreate, libraryBookUuid]);

  useEffect(() => {
    const fetchLibraryAuthors = async (term) => {
      try {
        setIsAuthorsLoading(true);
        // setOpenBackdrop(true);

        const res = await searchLibraryAuthors(accessToken, term);

        setAuthors(formatAuthorData(res.data));

        setError(null);
      } catch (err) {
        console.error(err);

        if (err.response && err.response.message) {
          setError(err.response.message);
        }
      } finally {
        setIsAuthorsLoading(false);
        // setOpenBackdrop(false);
      }
    };

    if (authorsSearchTerm && authorsSearchTerm.length > 3) {
      setTimeout(() => {
        fetchLibraryAuthors(authorsSearchTerm);
      }, 1000);
    }
    // } else {
    //   setIsAuthorsLoading(false);
    // }
  }, [accessToken, authorsSearchTerm]);

  useEffect(() => {
    const fetchLibraryPublishers = async (term) => {
      try {
        setIsPublishersLoading(true);

        const res = await searchLibraryPublishers(accessToken, term);

        setPublishers(formatPublisherData(res.data));

        setError(null);
      } catch (err) {
        console.error(err);

        if (err.response && err.response.message) {
          setError(err.response.message);
        }
      } finally {
        setIsPublishersLoading(false);
        setOpenBackdrop(false);
      }
    };

    if (publishersSearchTerm && publishersSearchTerm.length > 3) {
      fetchLibraryPublishers(publishersSearchTerm);
    }
  }, [accessToken, publishersSearchTerm]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsCategoriesLoading(true);

        const res = await getCategories(accessToken, shopUuid);

        setCategories(res.data.data.categories);
        // setCategoriesParentNodes(res.data.data.parentNodes.map((row) => row.uuid));

        // const optionsList = formatCategoryData(res.data.data.categories[0].children)
        //   .flatMap((category) => toOptions(category));

        setCategoryOptions(flattenTree(formatCategoryData(res.data.data.categories[0].children)));

        setError(null);
      } catch (err) {
        console.error(err);

        if (err.response && err.response.message) {
          setError(err.response.message);
        }
      } finally {
        setIsCategoriesLoading(false);
      }
    };

    fetchCategories();
  }, [accessToken, shopUuid]);

  const validateForm = () => {
    const errors = {};

    // if (formik.values.authors.length === 0) {
    //   errors.authors = 'Musíte vybrat alespoň jednoho autora';
    // }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(null);
    setSuccess(false);
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setError(Object.values(formErrors)[0]);

      return scrollToTop();
    }

    if (errorMessage !== null) {
      setError(errorMessage);
      setErrorMessage(null);

      return scrollToTop();
    }

    const selectedAuthors = Object.values(formik.values.authors).map((author) => author.uuid);
    const selectedCategories = Object.values(formik.values.categories).map(
      (category) => category.uuid
    );

    setIsSubmitting(true);

    const bookData = {
      libraryBookUuid,
      authors: selectedAuthors,
      categories: selectedCategories,
      title: formik.values.title,
      slug: formik.values.slug,
      summary: formik.values.summary,
      isbn: formik.values.isbn,
      publisher: formik.values.publisher,
      publisherName: formik.values.publisherName,
      placePublished: formik.values.placePublished,
      yearPublished: formik.values.yearPublished,
      publishingNumber: formik.values.publishingNumber,
      edition: formik.values.edition,
      format: formik.values.format,
      bookBinding: formik.values.bookBinding,
      pageCount: formik.values.pageCount + '',
      language: formik.values.language,
      rating: formik.values.rating,
      comment: formik.values.comment,
    };

    let createdBookUuid = null;

    const fd = new FormData();
    fd.append('image', uploadedBookImage);

    try {
      if (isCreate) {
        const res = await createBook(accessToken, shopUuid, bookData);

        createdBookUuid = res.data.uuid;

        if (uploadedBookImage) {
          await uploadBookImage(accessToken, shopUuid, createdBookUuid, fd);
        }
      } else if (isEdit) {
        await updateBook(accessToken, shopUuid, bookUuid, bookData);

        if (uploadedBookImage) {
          await uploadBookImage(accessToken, shopUuid, bookUuid, fd);
        }
      } else {
        setError('Při odesílání formuláře došlo k chybě. Zkuste to prosím znovu.');

        return scrollToTop();
      }

      setSuccess(true);

      await resetForm();
    } catch (err) {
      console.error('Book create error', err);

      if (err.response && err.response.data && err.response.data.message) {
        setError(
          `Chyba při odeslání formuláře: ${err.response.data.message}. Zkontrolujte prosím formulář a zkuste to znovu.`
        );
      } else {
        setError(`Chyba při odeslání formuláře. Zkontrolujte prosím formulář a zkuste to znovu.`);
      }

      return scrollToTop();
    } finally {
      setIsSubmitting(false);
    }

    if (isCreate && createdBookUuid) {
      return navigate(`/books/${createdBookUuid}/variants/create`);
    }

    scrollToTop();

    setTimeout(() => {
      navigate('/books', { replace: true });
    }, 3000);
  };

  if (isLoading) {
    return <Loader />;
  }

  const gridRowStyle = {
    p: 2,
    borderRight: '1px solid rgb(224, 224, 224);',
    borderBottom: '1px solid rgb(224, 224, 224);',
    borderLeft: '1px solid rgb(224, 224, 224);',
  };

  return (
    <>
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {success && <Alert severity="success">Kniha byla úspěšně uložena!</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        {!success && (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 1, width: { xs: '100%', sm: '100%', md: '90%', lg: '60%' } }}
          >
            {isCreate && bookUuid ? (
              <>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Kniha je již v databázi založena.
                </Typography>
                <AddButton
                  label={'Vytvořit variantu'}
                  onClick={(event) => handleNavigateToCreateVariant(event, bookUuid)}
                />
              </>
            ) : null}
            <FormControl sx={{ width: '100%' }}>
              <Grid container spacing={1} sx={gridRowStyle}>
                <Grid item xs={6}>
                  <Stack>
                    <Item>
                      <TextField
                        label="Název"
                        id="title"
                        type="text"
                        value={formik.values.title}
                        // onChange={(event) => formik.setFieldValue('title', event.target.value)}
                        onChange={(event) => handleSetTitle(event, event.target.value)}
                        sx={{ width: '100%' }}
                        required
                      />
                    </Item>
                    <Item>
                      <MultiSelectAutocomplete
                        options={authors}
                        label="Autoři"
                        placeholder="Autoři"
                        onChange={(event, value) => handleSetAuthors(event, value)}
                        onInputChange={updateAuthorsSearchTerm}
                        selectedValues={
                          formik.values.authors && Object.values(formik.values.authors).length
                            ? formatAuthorData(formik.values.authors)
                            : []
                        }
                        getOptionLabel={(option) => option.label}
                        filterSelectedOptions={false}
                        isLoading={isAuthorsLoading}
                      />
                    </Item>
                    <Item>
                      <TextField
                        label="ISBN"
                        id="isbn"
                        type="text"
                        value={formik.values.isbn}
                        onChange={(event) => formik.setFieldValue('isbn', event.target.value)}
                        sx={{ width: '100%' }}
                      />
                    </Item>
                    <Item>
                      <TextField
                        label="Počet stran"
                        id="page_count"
                        type="text"
                        value={formik.values.pageCount}
                        onChange={(event) => setNumericValue(event, 'pageCount', '\\+')}
                        sx={{ width: '100%' }}
                        required
                      />
                    </Item>
                    <Item>
                      {isCreate && !isCreateFromLibraryBook ? (
                        <InputAutocomplete
                          id="tags-outlined"
                          label="Vydavatel"
                          placeholder="Vydavatel"
                          onInputAddClick={handleSetPublisherName}
                          options={publishers}
                          value={formik.values.publisher}
                          getOptionLabel={(option) => option.label}
                          inputValue={publishersSearchTerm}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => handleSetPublisher(event, newValue)}
                          onInputChange={(newInputValue) => {
                            setPublishersSearchTerm(newInputValue);
                            handleSetPublisherName(newInputValue);
                          }}
                          isLoading={isPublishersLoading}
                        />
                      ) : (
                        <TextField
                          label="Vydavatel"
                          id="publisher_name"
                          type="text"
                          value={formik.values.publisherName}
                          onChange={(event) => handleSetPublisherName(event.target.value)}
                          sx={{ width: '100%' }}
                          required
                        />
                      )}
                    </Item>
                    <Item>
                      <TextField
                        label="Místo vydání"
                        id="place_published"
                        type="text"
                        value={formik.values.placePublished}
                        onChange={(event) =>
                          formik.setFieldValue('placePublished', event.target.value)
                        }
                        sx={{ width: '100%' }}
                        required
                      />
                    </Item>
                    <Item>
                      <TextField
                        label="Rok vydání"
                        id="year_published"
                        type="number"
                        value={formik.values.yearPublished}
                        onChange={(event) => setNumericValue(event, 'yearPublished')}
                        sx={{ width: '100%' }}
                        required
                      />
                    </Item>
                    <Item>
                      <TextField
                        label="Vydání"
                        id="publishing_number"
                        type="text"
                        value={formik.values.publishingNumber}
                        onChange={(event) =>
                          formik.setFieldValue('publishingNumber', event.target.value)
                        }
                        sx={{ width: '100%' }}
                      />
                    </Item>
                    <Item>
                      <TextField
                        label="Edice"
                        id="edition"
                        type="text"
                        value={formik.values.edition}
                        onChange={(event) => formik.setFieldValue('edition', event.target.value)}
                        sx={{ width: '100%' }}
                      />
                    </Item>
                    <Item>
                      <FormControl fullWidth>
                        <InputLabel id="format-label">Formát</InputLabel>
                        <Select
                          labelId="format-label"
                          id="format"
                          value={formik.values.format}
                          label="Formát"
                          onChange={(event) => formik.setFieldValue('format', event.target.value)}
                          required
                        >
                          {FORMAT_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Item>
                    <Item>
                      <FormControl fullWidth>
                        <InputLabel id="book_binding-label">Vazba</InputLabel>
                        <Select
                          labelId="book_binding-label"
                          id="book_binding"
                          value={formik.values.bookBinding}
                          label="Vazba"
                          onChange={(event) =>
                            formik.setFieldValue('bookBinding', event.target.value)
                          }
                          required
                        >
                          {BOOK_BINDING_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Item>
                    <Item>
                      <FormControl fullWidth>
                        <InputLabel id="language-label">Jazyk</InputLabel>
                        <Select
                          labelId="language-label"
                          id="language"
                          value={formik.values.language}
                          label="Jazyk"
                          onChange={(event) => formik.setFieldValue('language', event.target.value)}
                          required
                        >
                          {LANGUAGE_OPTIONS.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Item>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Item sx={{ justifyContent: 'center', alignItems: 'center' }}>
                      {isBookImageLoading && <SmallLoader />}
                      <FileUploader
                        handleChange={handleDraggedFileUploadChange}
                        name="file"
                        types={fileTypes}
                        label="Nahrajte nebo přetáhněte obrázek"
                        multiple={false}
                      />
                      <Box
                        component="img"
                        sx={{
                          padding: 1,
                          maxWidth: { xs: 200, md: 250 },
                          maxHeight: { xs: 200, md: 250 },
                        }}
                        alt={initialData.title || 'Náhled'}
                        src={bookImageUrl || NoImage}
                      />
                    </Item>
                    <Item>
                      <TextField
                        label="URL"
                        id="slug"
                        type="text"
                        value={formik.values.slug}
                        onChange={(event) => handleSetSlug(event, event.target.value)}
                        sx={{ width: '100%' }}
                        required
                      />
                    </Item>
                    <Item>
                      <CategorySelect
                        options={categoryOptions}
                        onChange={(event, value) => setCategory(event, value)}
                        selectedValues={
                          formik.values.categories && Object.values(formik.values.categories).length
                            ? Object.values(formik.values.categories)
                            : []
                        }
                        getOptionLabel={(option) => option.title}
                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                        required
                      />
                    </Item>
                    <Item>
                      <TextareaAutosize
                        id="summary"
                        defaultValue={formik.values.summary}
                        onChange={(event) => formik.setFieldValue('summary', event.target.value)}
                        placeholder="Obsah"
                        minRows={3}
                      />
                    </Item>
                    <Item>
                      <label htmlFor="rating" id="rating_label">
                        Hodnocení:{' '}
                      </label>
                      <Rating
                        id="rating"
                        label="Hodnocení"
                        name="rating"
                        value={formik.values.rating}
                        size="large"
                        onChange={(event, value) => formik.setFieldValue('rating', value)}
                      />
                    </Item>
                    <Item>
                      <TextareaAutosize
                        id="comment"
                        defaultValue={formik.values.comment}
                        onChange={(event) => formik.setFieldValue('comment', event.target.value)}
                        placeholder="Interní poznámka"
                        minRows={3}
                      />
                    </Item>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={gridRowStyle}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <SaveButton label={isEdit ? 'Upravit knihu' : 'Uložit knihu'} />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        )}
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BookBasicInfoForm;
