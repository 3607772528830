import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import CategoryIcon from '@mui/icons-material/Category';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useAuth } from '../../hooks/useAuth';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(3.5, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MiniDrawerSideMenu({ drawerWidth, handleDrawerClose, open, projects }) {
  const theme = useTheme();
  const { user, setCurrentProject } = useAuth();
  const currentProject = user ? user.currentProject : null;
  const currentShop = user ? user.currentShop : null;
  // const isSuperAdmin = user ? user.isSuperAdmin || false : false;
  // const isAdmin = user ? user.isAdmin || false : false;
  const navigate = useNavigate();

  const pages = currentShop
    ? [
        {
          name: 'Objednávky',
          path: '/orders',
          icon: <ChecklistIcon />,
        },
        {
          name: 'Kategorie',
          path: '/categories',
          icon: <CategoryIcon />,
          isSuperAdmin: true,
        },
        {
          name: 'Uživatelé',
          path: '/users',
          icon: <GroupIcon />,
          isSuperAdmin: true,
        },
        {
          name: 'Obchody',
          path: '/shops',
          icon: <ShoppingCartIcon />,
          isSuperAdmin: true,
        },
      ]
    : [];

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const handleDrawerCloseCallback = () => {
    handleDrawerClose();
  };

  const handleNavigate = (path) => {
    handleDrawerClose();

    navigate(path);
  };

  const handleSetProject = (projectUuid) => {
    setCurrentProject(projects.find((project) => project.uuid === projectUuid));

    navigate('/');
  };

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    })
  );

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        {projects.length > 1 ? (
          <Select
            labelId="format-label"
            id="project"
            value={currentProject ? currentProject.uuid : ''}
            displayEmpty
            label="Vyberte projekt"
            onChange={(event) => handleSetProject(event.target.value)}
            sx={{ backgroundColor: 'white' }}
            size={'small'}
          >
            {!currentProject && (
              <MenuItem key="default" value="">
                Vyberte projekt
              </MenuItem>
            )}
            {projects.map((project) => (
              <MenuItem key={project.uuid} value={project.uuid}>
                {project.title}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography>{currentProject && currentProject.title}</Typography>
        )}
        <IconButton onClick={handleDrawerCloseCallback}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {pages.map((page) => (
          <ListItem key={page.name} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={handleNavigate.bind(this, page.path)}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {page.icon}
              </ListItemIcon>
              <ListItemText primary={page.name} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Drawer>
  );
}
