import React from 'react';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const ShopSelector = ({ shops, currentShop, onShopSelect }) => {
  const handleSetShop = (shopUuid) => {
    onShopSelect(shopUuid);
  };

  const GoToShop = ({ shopUrl }) => {
    return (
      <IconButton component={Link} href={shopUrl} target="_blank" title="Přejít na Eshop">
        <ExitToAppIcon />
      </IconButton>
    );
  };

  if (currentShop && shops.length === 1) {
    return (
      <>
        <Typography variant="h6" noWrap sx={{ color: 'white', fontWeight: 'bold' }}>
          {currentShop.title}
        </Typography>
        <GoToShop shopUrl={currentShop.url} />
      </>
    );
  }

  return (
    <>
      <Select
        labelId="format-label"
        id="shop"
        value={currentShop ? currentShop.uuid : ''}
        displayEmpty
        label="Vyberte obchod"
        onChange={(event) => handleSetShop(event.target.value)}
        sx={{ my: 2, backgroundColor: 'white' }}
      >
        {!currentShop && (
          <MenuItem key="default" value="">
            Vyberte obchod
          </MenuItem>
        )}
        {shops.map((shop) => (
          <MenuItem key={shop.uuid} value={shop.uuid}>
            {shop.title}
          </MenuItem>
        ))}
      </Select>
      {currentShop && <GoToShop shopUrl={currentShop.url} />}
    </>
  );
};

export default ShopSelector;
