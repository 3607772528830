import React from 'react';
import Typography from '@mui/material/Typography';

const ListContentHeader = ({ icon, title }) => {
  return (
    <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold', marginTop: 5 }}>
      {icon} {title}
    </Typography>
  );
};

export default ListContentHeader;
