import React from 'react';
import PropTypes from 'prop-types';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import ActionButton from './ActionButton';

export default function DetailButton({
  label,
  onClick,
  sx,
  variant = 'contained',
  color = 'success',
}) {
  return (
    <ActionButton
      variant={variant}
      color={color}
      label={label}
      onClick={onClick}
      startIcon={<FindInPageOutlinedIcon />}
      sx={sx}
    />
  );
}

DetailButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
