import { useEffect, useState } from 'react';
import { getEnums } from '../api';
import { getGroupedEnumsWithDefaults } from '../helpers/index';

export const useEnums = (accessToken, shopUuid) => {
  const [enumGroups, setEnumGroups] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchEnums = async () => {
      try {
        setIsLoading(true);

        const res = await getEnums(accessToken, shopUuid);

        setEnumGroups(getGroupedEnumsWithDefaults(res.data));
        setError(null);
      } catch (err) {
        console.error(err);

        if (err.response && err.response.message) {
          setError(err.response.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (shopUuid) {
      fetchEnums();
    }
  }, [accessToken, shopUuid]);

  return { enumGroups, isLoading, error };
};
