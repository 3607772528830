import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

export default function ActionButton({
  label,
  onClick,
  startIcon,
  sx,
  variant = 'contained',
  color = 'success',
}) {
  const sxDefault = {
    ...sx,
  };

  return (
    <Button variant={variant} color={color} onClick={onClick} startIcon={startIcon} sx={sxDefault}>
      {label}
    </Button>
  );
}

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
