import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import EditButton from '../utils/buttons/EditButton';
import NoImage from '../../assets/images/noimage.jpg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: '100%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  display: 'block',
};

const BookDetailModal = ({ open, setOpen, book }) => {
  const navigate = useNavigate();
  const handleClose = () => setOpen(false);

  const handleEditBook = (event, bookUuid) => {
    navigate(`/books/${bookUuid}/edit`);
  };

  const gridRowStyle = {
    p: 2,
    borderRight: '1px solid rgb(224, 224, 224);',
    borderBottom: '1px solid rgb(224, 224, 224);',
    borderLeft: '1px solid rgb(224, 224, 224);',
  };

  const BookDetailItem = ({ label, value }) => (
    <Box sx={{ p: 1 }}>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton onClick={handleClose} sx={{ float: 'right' }}>
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Detail knihy #<b>{book.title}</b>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }} />
        <EditButton
          label={'Upravit'}
          onClick={(e) => handleEditBook(e, book.uuid)}
          sx={{ cursor: 'pointer' }}
        />
        <Grid container spacing={1} sx={gridRowStyle}>
          <Grid item xs={6}>
            <Stack>
              <BookDetailItem label={'Název'} value={book.title} />
              <BookDetailItem label={'Autoři'} value={book.full_authors} />
              <BookDetailItem label={'ISBN'} value={book.isbn} />
              <BookDetailItem label={'Počet stran'} value={book.page_count} />
              <BookDetailItem label={'Vydavatel'} value={book.publisher_name} />
              <BookDetailItem label={'Místo vydání'} value={book.place_published} />
              <BookDetailItem label={'Rok vydání'} value={book.year_published} />
              <BookDetailItem label={'Vydání'} value={book.publishing_number} />
              <BookDetailItem label={'Edice'} value={book.edition} />
              <BookDetailItem label={'Formát'} value={book.format} />
              <BookDetailItem label={'Vazba'} value={book.book_binding} />
              <BookDetailItem label={'Jazyk'} value={book.language} />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Box
                component="img"
                sx={{
                  padding: 1,
                  maxWidth: { xs: 200, md: 250 },
                  maxHeight: { xs: 200, md: 250 },
                }}
                alt={book.title || 'Náhled'}
                src={book.image || NoImage}
              />
              <BookDetailItem label={'URL'} value={book.slug} />
              <BookDetailItem
                label={'Kategorie'}
                value={
                  book.categories && book.categories.length > 0
                    ? book.categories.map((category) => category.title).join(', ')
                    : ''
                }
              />
              <BookDetailItem label={'Obsah'} value={book.summary} />
              <BookDetailItem label={'Hodnocení'} value={book.rating} />
              <BookDetailItem label={'Interní poznámka'} value={book.comment} />
            </Stack>
          </Grid>
        </Grid>
        <EditButton
          label={'Upravit'}
          onClick={(e) => handleEditBook(e, book.uuid)}
          sx={{ cursor: 'pointer' }}
        />
      </Box>
    </Modal>
  );
};

export default BookDetailModal;
