import ClearIcon from '@mui/icons-material/Clear';

const ErrorIcon = () => {
  return (
    <ClearIcon
      sx={{
        backgroundColor: '#DA1010FF',
        color: 'white',
        borderRadius: '50%',
        padding: 0.5,
      }}
    />
  );
};

export default ErrorIcon;
