import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

export default function InputAutocomplete({
  onInputChange,
  onInputAddClick,
  options,
  label,
  placeholder,
  inputValue,
  isLoading,
  ...otherProps
}) {
  return (
    <Autocomplete
      id="tags-outlined"
      options={options}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        if (event && event.type === 'blur') {
          onInputChange('');
        } else if (reason !== 'reset') {
          onInputChange(newInputValue);
        }
      }}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <IconButton
                  sx={{
                    visibility:
                      inputValue.length > 0 && options.length === 0 ? 'visible' : 'hidden',
                  }}
                  onClick={() => onInputAddClick(inputValue)}
                >
                  <AddIcon />
                </IconButton>
                {isLoading && <CircularProgress size={'2rem'} />}
              </>
            ),
            onKeyDown: (event) => {
              if (event.key === 'Enter') {
                event.preventDefault();

                onInputAddClick(inputValue);
              }
            },
          }}
        />
      )}
      {...otherProps}
    />
  );
}

InputAutocomplete.propTypes = {
  onInputAddClick: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
};
