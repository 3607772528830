import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Autorenew from '@mui/icons-material/Autorenew';
import PasswordIcon from '@mui/icons-material/Password';
import ClipboardCopy from '../utils/ClipboardCopy';
import SmallLoader from '../utils/SmallLoader';
import { updateLoggedUser } from '../../api';

const UserPasswordReset = ({ accessToken, user }) => {
  const [isClicked, setIsClicked] = useState(false);
  // const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleResetUserPassword = async () => {
    setIsLoading(true);

    try {
      const res = await updateLoggedUser(accessToken, user.uuid, user.email);

      setIsClicked(true);

      setPassword(res.data.data.password);
    } catch (err) {
      console.error('Password reset error', err.response);

      // if (err.response.status === 401) {
      //   setError('Špatné přihlašovací údaje');
      // } else {
      //   setError(err.response.data.message);
      // }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <SmallLoader />;
  }

  if (isClicked && password !== '') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <ClipboardCopy copyText={password} />
        </Grid>
        <Grid item xs={2}>
          <Autorenew sx={{ cursor: 'pointer' }} onClick={() => handleResetUserPassword()} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Button
      type="button"
      variant="outlined"
      className="btn btn-link"
      onClick={() => handleResetUserPassword()}
      startIcon={<PasswordIcon />}
      sx={{ whiteSpace: 'nowrap' }}
    >
      Reset hesla
    </Button>
  );
};

export default UserPasswordReset;
