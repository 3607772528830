import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Loader from '../utils/Loader';
import { getBookLog } from '../../api';
import BookLogDiffModal from './BookLogDiffModal';

const BookLogList = ({ accessToken, shopUuid, book }) => {
  const [error, setError] = useState(null);
  const [bookLogs, setBookLogs] = useState([]);
  const [bookLogDiffModalData, setBookLogDiffModalData] = useState([]);
  const [bookLogDiffModalOpen, setBookLogDiffModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenBookLogDiffModal = (event, data) => {
    setBookLogDiffModalData(data);
    setBookLogDiffModalOpen(true);
  };

  useEffect(() => {
    const fetchBokLogs = async () => {
      try {
        setIsLoading(true);

        const res = await getBookLog(accessToken, shopUuid, book.uuid);

        if (res) {
          setBookLogs(res.data);
        } else {
          console.error('Book logs not found');

          setError('Log akcí nenalezen');
        }
      } catch (err) {
        console.error('Fetch Book logs error', err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBokLogs();
  }, [accessToken, shopUuid, book]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box>
      {error && <Alert severity="error">{error}</Alert>}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Čas</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Akce</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {bookLogs.map((log) => (
              <TableRow key={log.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {dayjs(new Date(log.created_at)).format('DD.MM.YYYY HH:mm:ss')}
                </TableCell>
                <TableCell>{log.message}</TableCell>
                <TableCell>
                  {log.action_type === 'UPDATED' && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleOpenBookLogDiffModal}
                    >
                      Zobrazit změny
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <BookLogDiffModal
        open={bookLogDiffModalOpen}
        setOpen={setBookLogDiffModalOpen}
        bookTitle={book.title}
        bookLog={bookLogDiffModalData}
      />
    </Box>
  );
};

export default BookLogList;
