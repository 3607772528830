import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

const DashboardCard = ({ title, content }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader title={title} />
      <CardContent>{content}</CardContent>
    </Card>
  );
};

export default DashboardCard;
