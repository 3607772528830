import slugify from 'slugify';

export function createSlug(str) {
  if (!str || str.length === 0) {
    return undefined;
  }

  return slugify(str.replace(/[.,_()"':;~!@#$%^&*]/g, ''), {
    lower: true,
  });
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function formatEnumsData(data) {
  return data.map((row) => {
    return {
      value: row.const_key,
      label: row.title,
      color: row.color,
    };
  });
}

const addDefaultChooseOption = (data) => {
  return [
    {
      value: '',
      label: 'Vyberte',
      color: '',
    },
    ...data,
  ];
};

const addOtherOption = (data) => {
  return [
    ...data,
    {
      value: 'OTHER',
      label: 'Jiný',
      color: '',
    },
  ];
};

export function getGroupedEnumsWithDefaults(allEnums) {
  return {
    CONDITION_CATEGORY_OPTIONS: formatEnumsData(
      allEnums.filter((enumItem) => {
        return enumItem.enum_type === 'CONDITION_CATEGORY';
      })
    ),
    FORMAT_OPTIONS: addDefaultChooseOption(
      addOtherOption(
        formatEnumsData(
          allEnums.filter((enumItem) => {
            return enumItem.enum_type === 'FORMAT';
          })
        )
      )
    ),
    BOOK_BINDING_OPTIONS: addDefaultChooseOption(
      addOtherOption(
        formatEnumsData(
          allEnums.filter((enumItem) => {
            return enumItem.enum_type === 'BOOK_BINDING';
          })
        )
      )
    ),
    LANGUAGE_OPTIONS: addOtherOption(
      formatEnumsData(
        allEnums.filter((enumItem) => {
          return enumItem.enum_type === 'LANGUAGE';
        })
      )
    ),
  };
}

export function formatCategoryData(data) {
  return data.map((row) => {
    return {
      ...row,
      id: row.uuid,
      label: row.title,
      children: row.children ? formatCategoryData(row.children) : null,
    };
  });
}

export function flattenTree(treeData) {
  const flattenedArray = [];

  function recursiveFlatten(node) {
    flattenedArray.push(node);

    if (node.children) {
      for (const child of node.children) {
        recursiveFlatten(child);
      }

      delete node.children;
    }
  }

  for (const node of treeData) {
    recursiveFlatten(node);
  }

  return flattenedArray;
}
