import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  return (
    <AuthContext.Provider value={{ user, updateUser: setUser }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const { user, updateUser } = useContext(AuthContext);
  const { setItem, getItem } = useLocalStorage();

  useEffect(() => {
    const user = getItem('user');

    if (user) {
      updateUser(JSON.parse(user));
    }
  }, []);

  const login = (data) => {
    updateUser(data);

    setItem('user', JSON.stringify(data));
  };

  const setCurrentProject = (project) => {
    const data = { ...user, currentProject: project };

    updateUser(data);

    setItem('user', JSON.stringify(data));
  };

  const setCurrentShop = (shop) => {
    const data = { ...user, currentShop: shop };

    updateUser(data);

    setItem('user', JSON.stringify(data));
  };

  const logout = () => {
    updateUser(null);

    setItem('user', '');
  };

  return { user, login, setCurrentProject, setCurrentShop, logout };
};
