import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'dayjs/locale/cs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import ContentHeader from '../layout/ContentHeader';
import BackButton from '../utils/buttons/BackButton';
import SaveButton from '../utils/buttons/SaveButton';
import Item from '../utils/Item';
import Loader from '../utils/Loader';
import { getCategory, createCategory, updateCategory } from '../../api';
import { createSlug, scrollToTop } from '../../helpers';
import { useFormik } from 'formik';

const CategoryForm = ({ accessToken, shopUuid, isCreate }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isLoading = isCategoriesLoading || isSubmitting;

  const navigate = useNavigate();

  const formInitialValues = {
    uuid: null,
    title: '',
    slug: '',
    description: '',
    isActive: true,
  };

  const [categoryInitialData, setCategoryInitialData] = useState(formInitialValues);

  const { uuid: categoryUuid } = useParams();

  const formik = useFormik({
    initialValues: categoryInitialData,
    enableReinitialize: true,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setIsCategoriesLoading(true);

        const res = await getCategory(accessToken, shopUuid, categoryUuid);

        if (res) {
          const categoryDataResult = res.data;

          setCategoryInitialData({
            uuid: categoryDataResult.uuid,
            title: categoryDataResult.title,
            slug: categoryDataResult.slug,
            description: categoryDataResult.description,
            isActive: categoryDataResult.is_active,
          });
        } else {
          console.error('Category not found');

          setError('Kategorie nenalezena');
        }
      } catch (err) {
        console.error('Fetch Category error', err);

        setError(err.response.message);
      } finally {
        setIsCategoriesLoading(false);
      }
    };

    if (categoryUuid && !isCreate) {
      fetchCategory();
    }
  }, [accessToken, shopUuid, isCreate, categoryUuid]);

  const resetForm = async () => {
    await formik.resetForm();
  };

  const handleSetTitle = (event, value) => {
    event.preventDefault();

    formik.setFieldValue('title', value);

    handleSetSlug(event, value);
  };

  const handleSetSlug = (event, value) => {
    event.preventDefault();

    if (value && value !== '') {
      formik.setFieldValue('slug', createSlug(value));
    } else {
      formik.setFieldValue('slug', '');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(null);
    setSuccess(false);

    setIsSubmitting(true);

    const categoryData = {
      title: formik.values.title,
      slug: formik.values.slug,
      description: formik.values.description,
      isActive: formik.values.isActive,
    };

    try {
      if (isCreate) {
        await createCategory(accessToken, shopUuid, categoryData);
      } else if (categoryUuid && !isCreate) {
        await updateCategory(accessToken, shopUuid, categoryUuid, categoryData);
      } else {
        setError('Při odesílání formuláře došlo k chybě. Zkuste to prosím znovu.');

        return scrollToTop();
      }

      setSuccess(true);

      await resetForm();
    } catch (err) {
      console.error('Category create error', err);

      if (err.response && err.response.data && err.response.data.message) {
        setError(
          `Chyba při odeslání formuláře: ${err.response.data.message}. Zkontrolujte prosím formulář a zkuste to znovu.`
        );
      } else {
        setError(`Chyba při odeslání formuláře. Zkontrolujte prosím formulář a zkuste to znovu.`);
      }

      return scrollToTop();
    } finally {
      setIsSubmitting(false);
    }

    setTimeout(() => {
      navigate('/categories', { replace: true });
    }, 3000);
  };

  if (isLoading) {
    return <Loader />;
  }

  const gridRowStyle = {
    p: 2,
    borderRight: '1px solid rgb(224, 224, 224);',
    borderBottom: '1px solid rgb(224, 224, 224);',
    borderLeft: '1px solid rgb(224, 224, 224);',
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ContentHeader
          title={!isCreate ? categoryInitialData.title : 'Nová kategorie'}
          navButtonBack={
            <BackButton
              label={'Zpět na výpis kategorií'}
              url={`/categories`}
              sx={{ float: 'left' }}
            />
          }
        />
        {success && <Alert severity="success">Kategorie byla úspěšně uložena!</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        {!success && (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 1, width: { xs: '100%', sm: '100%', md: '90%', lg: '60%' } }}
          >
            <FormControl sx={{ width: '100%' }}>
              <Grid container spacing={1} sx={gridRowStyle}>
                <Grid item xs={6}>
                  <Stack>
                    <Item>
                      <TextField
                        label="Název"
                        id="title"
                        type="text"
                        value={formik.values.title}
                        // onChange={(event) => formik.setFieldValue('title', event.target.value)}
                        onChange={(event) => handleSetTitle(event, event.target.value)}
                        sx={{ width: '100%' }}
                        required
                      />
                    </Item>
                    <Item>
                      <TextField
                        label="URL"
                        id="slug"
                        type="text"
                        value={formik.values.slug}
                        onChange={(event) => handleSetSlug(event, event.target.value)}
                        sx={{ width: '100%' }}
                        required
                      />
                    </Item>
                    <Item>
                      <TextareaAutosize
                        id="description"
                        defaultValue={formik.values.description}
                        onChange={(event) =>
                          formik.setFieldValue('description', event.target.value)
                        }
                        placeholder="Popis kategorie"
                        minRows={3}
                      />
                    </Item>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={gridRowStyle}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <SaveButton
                    label={categoryUuid && !isCreate ? 'Upravit kategorii' : 'Uložit kategorii'}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default CategoryForm;
