import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';

const QuantityBox = ({ onChange, initialValue, minimumValue, id, labelId }) => {
  const [quantity, setQuantity] = useState(initialValue || 0);

  const handleIncrement = (event) => {
    event.preventDefault();

    updateQuantity(quantity + 1);
  };

  const handleDecrement = (event) => {
    event.preventDefault();

    if (quantity > minimumValue) {
      updateQuantity(quantity - 1);
    }
  };

  const handleChange = (event) => {
    event.preventDefault();

    const regex = /^[0-9\b]+$/;
    const valueInt = parseInt(event.target.value);

    if (regex.test(event.target.value) && valueInt >= minimumValue) {
      updateQuantity(valueInt);
    }
  };

  const updateQuantity = (value) => {
    setQuantity(value);

    onChange(value);
  };

  return (
    <ButtonGroup
      aria-labelledby={labelId}
      variant="contained"
      size="small"
      aria-label="small outlined button group"
      key={id}
    >
      <Button onClick={handleDecrement}>-</Button>
      <TextField
        type="number"
        onChange={handleChange}
        value={quantity}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        size="small"
        // InputProps={{
        //     startAdornment: <InputAdornment position="end">ks</InputAdornment>,
        // }}
        style={{ width: '60px', textAlign: 'center' }}
      />
      <Button onClick={handleIncrement}>+</Button>
    </ButtonGroup>
  );
};

export default QuantityBox;
