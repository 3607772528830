import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ProjectDashboard from './dashboard/ProjectDashboard';
import ShopDashboard from './dashboard/ShopDashboard';
import UserDashboard from './dashboard/UserDashboard';
import Loader from './utils/Loader';
import { getLoggedUserDashboard, getProjectDashboard, getShopDashboard } from '../api';

const Dashboard = ({ accessToken, project, shop }) => {
  const [userDashboard, setUserDashboard] = useState([]);
  const [projectDashboard, setProjectDashboard] = useState([]);
  const [shopDashboard, setShopDashboard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserDashboard = async () => {
      try {
        const res = await getLoggedUserDashboard(accessToken);

        if (res) {
          setUserDashboard(res.data.data);
        } else {
          console.error('Error fetching user dashboard');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchProjectDashboard = async () => {
      try {
        const res = await getProjectDashboard(accessToken, project.uuid);

        if (res) {
          setProjectDashboard(res.data.data);
        } else {
          console.error('Error fetching project dashboard');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchShopDashboard = async () => {
      try {
        const res = await getShopDashboard(accessToken, shop.uuid);

        if (res) {
          setShopDashboard(res.data.data);
        } else {
          console.error('Error fetching shop dashboard');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDashboard();

    if (project) {
      fetchProjectDashboard();
    }

    if (shop) {
      fetchShopDashboard();
    }
  }, [accessToken, project, shop]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2}>
          {shop && (
            <Grid item>
              <ShopDashboard shop={shop} data={shopDashboard} />
            </Grid>
          )}
          {project && false && (
            <Grid item>
              <ProjectDashboard project={project} data={projectDashboard} />
            </Grid>
          )}
          <Grid item>
            <UserDashboard data={userDashboard} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Dashboard;
