import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import 'dayjs/locale/cs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import FeedIcon from '@mui/icons-material/Feed';
import PatternIcon from '@mui/icons-material/Pattern';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import BookBasicInfoForm from './BookBasicInfoForm';
import BookLogList from './BookLogList';
import BookVariantList from './BookVariantList';
import ContentHeader from '../layout/ContentHeader';
import BackButton from '../utils/buttons/BackButton';
import WebIcon from '../utils/icons/WebIcon';
import Loader from '../utils/Loader';
import TabPanel from '../utils/TabPanel';
import { getBook, getBooks } from '../../api';
import { createSlug } from '../../helpers';
import { useHash } from '../../hooks/useHash';

const BookForm = ({ accessToken, shopUuid, enumGroups, isCreate, defaultTab = 0 }) => {
  const [error, setError] = useState(null);
  const [currentTabValue, setCurrentTabValue] = useState(defaultTab);
  const [isLoading, setIsLoading] = useState(false);
  const [hash, setHash] = useHash();

  const { FORMAT_OPTIONS, BOOK_BINDING_OPTIONS, LANGUAGE_OPTIONS } = enumGroups;

  const formInitialValues = {
    uuid: null,
    libraryBookUuid: null,
    authors: {},
    fullAuthors: '',
    categories: {},
    title: '',
    slug: '',
    permalink: '',
    summary: '',
    isbn: '',
    pageCount: '',
    publisher: null,
    publisherName: '',
    placePublished: '',
    yearPublished: '',
    publishingNumber: '',
    edition: '',
    format: FORMAT_OPTIONS[0].value,
    bookBinding: BOOK_BINDING_OPTIONS[0].value,
    language: LANGUAGE_OPTIONS[0].value,
    rating: null,
    comment: '',
    variants: [],
  };

  const tabs = {
    0: 'basic-info',
    1: 'variants',
    2: 'actions-log',
  };

  const [bookInitialData, setBookInitialData] = useState(formInitialValues);

  const { uuid: bookUuid } = useParams();
  const { state } = useLocation();
  const libraryBookData = isCreate && state ? state.bookData || false : false;
  const libraryBookUuid = libraryBookData.uuid || false;

  const handleTabChange = (event, newValue) => {
    setCurrentTabValue(newValue);

    setHash('#' + tabs[newValue]);
  };

  const tabProps = (index) => {
    return {
      id: `book-form-tab-${index}`,
      'aria-controls': `book-form-tabpanel-${index}`,
    };
  };

  const assembleSlug = (urlBookData) => {
    const parts = [];

    if (urlBookData.full_authors) {
      parts.push(urlBookData.full_authors);
    }

    if (urlBookData.title) {
      parts.push(urlBookData.title);
    }

    if (urlBookData.year_published) {
      parts.push(urlBookData.year_published);
    }

    return parts.join('-');
  };

  const updateBookInitialData = async (bookData) => {
    const bookVariants = [];

    bookData.variants.map((variant) => {
      const variantInitialValues = {
        uuid: variant.uuid,
        shopExternalId: variant.shop_external_id,
        purchasePrice: variant.purchase_price,
        sellingPrice: variant.selling_price,
        bookBindingHasWrap: variant.book_binding_has_wrap || false,
        conditionCategory: variant.condition_category || '',
        stockCount: variant.stock_count,
        comment: variant.comment,
        image: variant.image,
        galleryImages: variant.gallery_images,
        publishedAt: variant.published_at,
        createdAt: variant.created_at,
        updatedAt: variant.updated_at,
      };

      bookVariants.push(variantInitialValues);

      return variant;
    });

    setBookInitialData({
      uuid: bookData.uuid,
      libraryBookUuid: bookData.library_book_uuid,
      authors: bookData.authors || {},
      fullAuthors: bookData.full_authors || null,
      categories: bookData.categories,
      title: bookData.title,
      slug: bookData.slug,
      permalink: bookData.permalink,
      summary: bookData.summary || '',
      isbn: bookData.isbn || '',
      pageCount: bookData.page_count || '',
      publisher: bookData.publisher || undefined,
      publisherName: bookData.publisher_name || '',
      placePublished: bookData.place_published || '',
      yearPublished: bookData.year_published || '',
      publishingNumber: bookData.publishing_number || '',
      edition: bookData.edition || '',
      format: bookData.format || '',
      bookBinding: bookData.book_binding || '',
      language: bookData.language || '',
      rating: bookData.rating,
      comment: bookData.comment,
      image: bookData.image,
      variants: bookVariants,
    });
  };

  useEffect(() => {
    const fetchBookByLibraryBookUuid = async () => {
      try {
        setIsLoading(true);

        const res = await getBooks(accessToken, shopUuid, 1, 1, {
          library_book_uuid: libraryBookUuid,
        });

        if (res && res.data.data.length > 0) {
          await updateBookInitialData(res.data.data[0]);
        } else {
          const libraryBookInitialValues = {
            uuid: null,
            libraryBookUuid: libraryBookData.uuid,
            authors: libraryBookData.authors,
            fullAuthors: libraryBookData.full_authors || null,
            title: libraryBookData.title,
            slug: createSlug(assembleSlug(libraryBookData)),
            isbn: libraryBookData.isbn || '',
            pageCount: libraryBookData.page_count,
            publisher: libraryBookData.publisher || { id: '', label: '' },
            publisherName: libraryBookData.publisher_name || '',
            placePublished: libraryBookData.place_published,
            yearPublished: libraryBookData.year_published,
            publishingNumber: libraryBookData.publishing_number || '',
            edition: libraryBookData.edition || '',
            format: libraryBookData.format || '',
            bookBinding: libraryBookData.book_binding || '',
            language: libraryBookData.language || '',
            variants: [],
          };

          setBookInitialData({ ...formInitialValues, ...libraryBookInitialValues });
        }
      } catch (err) {
        console.error('Fetch Book error', err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (isCreate && libraryBookUuid) {
      fetchBookByLibraryBookUuid();
    }
  }, [isCreate, libraryBookUuid]);

  useEffect(() => {
    const fetchBook = async () => {
      try {
        setIsLoading(true);

        const res = await getBook(accessToken, shopUuid, bookUuid);

        if (res) {
          await updateBookInitialData(res.data);
        } else {
          console.error('Book not found');

          setError('Kniha nenalezena');
        }
      } catch (err) {
        console.error('Fetch Book error', err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (bookUuid && !isCreate) {
      fetchBook();
    }
  }, [accessToken, shopUuid, isCreate, bookUuid]);

  useEffect(() => {
    const hashSlug = hash.slice(1);

    if (hashSlug && hashSlug !== '') {
      const slugIndex = Object.keys(tabs).find((key) => tabs[key] === hashSlug);

      if (slugIndex) {
        setCurrentTabValue(parseInt(slugIndex));
      }
    }
  }, [hash]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ContentHeader
          title={!isCreate ? bookInitialData.title : 'Nová kniha'}
          navButtonBack={
            !isCreate && (
              <BackButton label={'Zpět na výpis knih'} url={`/books`} sx={{ float: 'left' }} />
            )
          }
        />
        {bookInitialData.permalink && (
          <IconButton component={Link} href={bookInitialData.permalink} target="_blank">
            <WebIcon />
          </IconButton>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          {!isCreate && (
            <Tabs
              variant="fullWidth"
              value={currentTabValue}
              onChange={handleTabChange}
              textColor="inherit"
              indicatorColor="primary"
              aria-label="book form tabs"
            >
              <Tab icon={<FeedIcon />} label="Základní" {...tabProps(0)} />
              <Tab
                icon={<SplitscreenIcon />}
                label="Varianty"
                {...tabProps(1)}
                disabled={isCreate}
              />
              <Tab icon={<PatternIcon />} label="Log akcí" {...tabProps(2)} disabled={isCreate} />
            </Tabs>
          )}
        </Box>
        <TabPanel value={currentTabValue} index={0} id="book-form" sx={{ width: '100%' }}>
          <BookBasicInfoForm
            accessToken={accessToken}
            shopUuid={shopUuid}
            enumGroups={enumGroups}
            isCreate={isCreate}
            initialData={bookInitialData}
          />
        </TabPanel>
        <TabPanel value={currentTabValue} index={1} id="book-variant-list">
          <BookVariantList
            accessToken={accessToken}
            shopUuid={shopUuid}
            enumGroups={enumGroups}
            book={bookInitialData}
            variants={bookInitialData.variants}
          />
        </TabPanel>
        <TabPanel value={currentTabValue} index={2} id="book-log-list">
          <BookLogList accessToken={accessToken} shopUuid={shopUuid} book={bookInitialData} />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default BookForm;
