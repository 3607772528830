const ENV_LOCAL = 'local';
const ENV_TEST = 'test';
const ENV_PROD = 'production';

const API_SERVER_NAME = 'antikmanazer.cz';

function getEnvironment(hostname) {
  switch (hostname) {
    case 'localhost':
      return ENV_LOCAL;
    case `test.${API_SERVER_NAME}`:
      return ENV_TEST;
    default:
      return ENV_PROD;
  }
}

function getApiUrl(env) {
  if (env === ENV_LOCAL) {
    return 'http://localhost:8123';
  }

  if (env === ENV_TEST) {
    return `https://test.app.${API_SERVER_NAME}`;
  }

  return `https://app.${API_SERVER_NAME}`;
}

export const ENVIRONMENT = getEnvironment(window.location.hostname);
export const API_BASE_URL = getApiUrl(ENVIRONMENT);

// export const API_BASE_URL = process.env.REACT_APP_ENV || `https://app.${API_SERVER_NAME}`;
// export const API_BASE_URL = process.env.NODE_ENV === 'development'
//     ? 'http://localhost:8123'
//     : `https://app.${API_SERVER_NAME}`;
