import React from 'react';
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ActionButton from './ActionButton';

export default function AddButton({
  label,
  onClick,
  sx,
  variant = 'contained',
  color = 'success',
}) {
  const sxDefault = {
    margin: 1,
    ...sx,
  };

  return (
    <ActionButton
      variant={variant}
      color={color}
      label={label}
      onClick={onClick}
      startIcon={<AddCircleOutlineIcon />}
      sx={sxDefault}
    />
  );
}

AddButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
